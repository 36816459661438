import cx from "classnames";
import React from "react";
import getAsset from "../utils/getAsset";
import Button from "./Buttons/Button";
import styles from "./ItemSelect.module.scss";

const ItemSelect = ({ heading, description, onClick, image = false }) => {
	if (!image) image = getAsset("/img/avatar-logo.png");

	return (
		<div
			className={cx(
				styles.itemSelect,
				"clearfix flex flex-col justify-between h-full "
			)}
		>
			<div className="flex-1 pb-2">
				<img
					src={image}
					className="shadow-lg max-w-[150px] mx-auto rounded-xl"
				/>
				<h3 className="text-xl font-semibold pt-3 !m-0">{heading}</h3>
				<p className="pb-2">{description}</p>
			</div>
			<div className="flex-shrink ">
				<Button
					primary
					small
					style={{ float: "none", marginBottom: "10px" }}
					onClick={onClick}
					rightIcon={true}
					icon={"arrow-right"}
					noMarginRight
				>
					Select
				</Button>
			</div>
		</div>
	);
};
export default ItemSelect;
