import { LargeTextInput, Option } from "components/PropertyEditor";
import { useState } from "react";
import {Preview} from "./Preview";

export const CustomizeWidgetForm = () => {
	const [state, setState] = useState({
		title: "",
		description: "",
		size: 100,
		borderColor: "#000000",
		borderWidth: 0,
		shadow: 0,
		style: "bubble",
		position: "bottom-right",
		delay: 0,
		overlayText: "Click Me!",
		overlayTextColor: "#ffffff",
		overlayTextSize: 24,
	});
	const { title, description } = state;
	return (
		<div className="grid grid-cols-5 max-w-7xl px-4">
			<div className="col-span-2">

			<div className="form-control">
				<label htmlFor="title">Size</label>
				<input
					id="title"
					type="text"
					name="title"
					value={title}
					onChange={(e) => setState({ title: e.target.value })}
				/>
			</div>
			<div className="form-control">
				<label htmlFor="title">Border Color</label>
				<input
					id="title"
					type="text"
					name="title"
					value={title}
					onChange={(e) => setState({ title: e.target.value })}
				/>
			</div>
			<div className="form-control">
				<label htmlFor="title">Border Width</label>
				<input
					id="title"
					type="text"
					name="title"
					value={title}
					onChange={(e) => setState({ title: e.target.value })}
				/>
			</div>
			</div>
			<div className="col-span-3">
				<Preview />
			</div>
	
		</div>
	);
};
