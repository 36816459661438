import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Upgrade.module.scss';
import cx from 'classnames';
import Icon from './Icon';

export default function ({text, url}) {
    const btnText = 'Click Here To Upgrade';

    return(
      <div className={cx(styles.wrapper, 'rounded-xl shadow-md')}>
        <div>
			<Icon name={'exclamation-circle'} className={cx(styles.icon, 'text-6xl mb-6')} />
          <h2 className="text-3xl mb-2">Feature Unavailable</h2>
          <p>{text || 'You must upgrade to use this feature'}</p>
          <p className="mt-4 text-xl">
              {
                  <Link to="/upgrade">Click here to upgrade</Link>
              }
          </p>
        </div>
      </div>
    );
}
