import React, { useEffect, useState, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { usePlayer } from '@/graphql/LocalState/player';
import { EventListener } from '@/components/EventListener';
import { VIDEO_SCRUB } from '@/utils/EventEmitter';

const PLAYER_QUERY = gql`
  query player {
    player @client {
      playing
      muted
      ready
      showGrid
      playedSeconds
      duration
    }
  }
`;

const StaticMediaPlayer = ({ media, node }) => {
    const [secs, setSecs] = useState(0);
    const { data, loading, error } = useQuery(PLAYER_QUERY);
    const { updatePlayer } = usePlayer();
    
    if (loading || error) return null;
    
    const { playing, duration, playedSeconds } = data.player;

    useEffect(() => {
        updatePlayer('ready', true);
        return () => {
            updatePlayer('ready', false);
        }
    }, [])

    useEffect(() => {
        let timer = null;
        if(playing) {
            timer = setInterval(() => {
                if(secs < duration) {
                    setSecs(prev => prev + 0.01);
                    updatePlayer('playedSeconds', secs);
                } else {
                    setSecs(prev => duration);
                    updatePlayer('playedSeconds', duration);
                    updatePlayer('playing', false);
                }
            }, 10);
        } else {
            if(timer)
                clearInterval(timer);
        }

        return () => {
            if(timer) clearInterval(timer);
        }
    }, [playing, secs, duration]);
    
    const seekToEvent = useCallback((_playedSecs) => {
        setSecs(duration * _playedSecs);
    }, [duration]);

    const { background_color } = node;

    return (
        <EventListener name={VIDEO_SCRUB} func={seekToEvent}>
            {media && !!media.is_image && (
            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'black',
                    borderRadius: '10px',
                    overflow: 'hidden'
                }}
                >
                <img src={media.thumbnail_url} className={'img-fluid'} />
                </div>
            )}

            {!media && <div style={{ background: background_color, height: '100%', width: '100%', borderRadius: '10px' }}>&nbsp;</div>}
        </EventListener>
    )
}

export default StaticMediaPlayer;