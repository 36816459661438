import React, { useState } from 'react';

import { Icon } from 'components';
import { MediaLibraryFilters, MediaLibraryMediaList } from './index';
import { PageBody } from 'components/PageBody/PageBody';
import { dashboardPath } from 'modules/dashboard/routes';
import { setBreadcrumbs } from '@/graphql/LocalState/breadcrumb';
import { setPageHeader } from '@/graphql/LocalState/pageHeading';
import { UploadMediaButton } from '../UploadMediaButton';
import AddMediaModals from 'modules/media/components/AddMediaModals';

const CRUMBS = [
	{ text: 'Dashboard', link: dashboardPath() },
	{ text: 'Media Library' },
];

/**
 * Render media library page
 * @returns {*}
 * @constructor
 */
export const MediaLibraryPage = () => {
	const [showMediaZone, setShowMediaZone] = useState(false);

	const onDoneUploadVideo = () => {};

	setBreadcrumbs(CRUMBS);

	setPageHeader('Media Library');

	return (
		<PageBody
			subnav={<div style={{ marginBottom: 15 }} />}
			// commented out by MagicPalm 2022-10-25 TODO
			// right={
			// 	<div>
			// 		<UploadMediaButton
			// 			mediaLibraryPage={true}
			// 			show={showMediaZone}
			// 			showUploadFileArea
			// 			onDone={onDoneUploadVideo}
			// 			toggleMediaZone={() => setShowMediaZone(!showMediaZone)}
			// 		>
			// 			<Icon name={'cloud-upload'} /> Upload New Media
			// 		</UploadMediaButton>
			// 	</div>
			// }
		>
			<div className="px-4 mb-4">
				<h2 className="text-2xl font-bold mb-2">Manage Your Media Library</h2>
				<p></p>
			</div>			
			<div className="max-w-7xl ">
				<MediaLibraryFilters />
			</div>
			<div className='pt-4'>
				<MediaLibraryMediaList />
			</div>
			<AddMediaModals />
		</PageBody>
	);
};
