export const Preview = () => {
	return (
		<div class="flex flex-col items-center pl-12">
			<div class="browser-window w-full ">
				<div class="browser-toolbar">
					<div class="browser-button bg-red-500"></div>
					<div class="browser-button bg-yellow-500"></div>
					<div class="browser-button bg-green-500"></div>
				</div>
				<div class="browser-content pb-[75px] px-4 pt-4 relative">
					<div class="flex justify-between items-center ">
						<div className="bg-gray-200 rounded-full h-[60px] w-[60px]">&nbsp;</div>
						<div className="flex gap-x-1">
							<div className="bg-gray-200 rounded-md h-[15px] w-[70px]">&nbsp;</div>
							<div className="bg-gray-200 h-[15px] rounded-md w-[70px] ">&nbsp;</div>
							<div className="bg-gray-200 h-[15px] rounded-md w-[70px] ">&nbsp;</div>
						</div>
					</div>
					<div className="bg-gray-200 h-[45px] mt-3 rounded-md">&nbsp;</div>
					<div className="bg-gray-200 h-[85px] mt-3 rounded-md">&nbsp;</div>
					<div className="bg-gray-200 h-[125px] mt-3 rounded-md">&nbsp;</div>
					<div className="bg-gray-200 h-[45px] mt-3 rounded-md">&nbsp;</div>
					{/* <BubblePreview /> */}
				</div>
			</div>
			<div class="flex justify-center  gap-x-4 text-3xl mt-4">
				<p><i class="fa-solid fa-desktop"></i></p>
				<p><i class="fa-regular fa-mobile"></i></p>
			</div>
		</div>
	);
};
