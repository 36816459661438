import { Button, LinkButton } from 'components/Buttons';
import { ErrorMessage, Icon } from 'components';

const BubbleNodeEditor = ({ node, onChange }) => {
	return (
		<div className="-mt-[80px] flex ">
			<div className="h-[750px] w-[450px] bg-slate-300 rounded-xl"></div>
			<div class="flex gap-x-4 items-center h-[30px]">
				<h2 class="pl-[30px]">Video End Action </h2>
				<div>
				<LinkButton
					primary
					small
					right
				>
					<Icon icon={"plus"} /> Select Action Type
				</LinkButton>
				</div>
			</div>
		</div>
	);
};

export { BubbleNodeEditor };
