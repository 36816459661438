export const generalVideos =  [
  {
    name: 'Create a Project',
    description: 'Learn how to create a new project',
    link: 'https://iframe.mediadelivery.net/embed/167712/9649142f-4d67-41fd-9cca-9626a656ade2',
    category: ''
  },
  {
    name: 'The Canvas',
    description: 'Learn about the interactive video canvas',
    link: 'https://iframe.mediadelivery.net/embed/167712/ea49bf3a-0a37-4c91-8cf2-3387ca7e734a',
    category: ''
  },
  {
    name: 'Project Tabs',
    description: 'Learn about the other tabs in your project',
    link: 'https://iframe.mediadelivery.net/embed/167712/25535b9e-83e5-498c-bf99-e232fc9ff2c7',
    category: ''
  },
  {
    name: 'Nodes',
    description: 'Learn about the nodes in your interactive video',
    link: 'https://iframe.mediadelivery.net/embed/167712/55bba2d9-3486-4af2-ac2b-fea344534fc9',
    category: ''
  },

]

export const proVideos = [
  {
    name: 'Project Chapters',
    description: 'Learn how chapters in your interactive videos',
    link: 'https://vimeo.com/396667782',
    category: ''
  },
  {
    name: 'Making Your Video Interactive',
    description: 'Learn how to edit a node and make your videos interactive',
    link: 'https://vimeo.com/396667974',
    category: ''
  },
];

export const agencyVideos = [
  {
    name: 'Project Settings',
    description: 'Learn how to manage the settings of your interactive video',
    link: 'https://vimeo.com/396668039',
    category: ''
  },
  {
    name: 'Interaction Layers',
    description: 'Learn the advanced tricks of our latest feature',
    link: 'https://vimeo.com/396673244',
    category: ''
  },
];