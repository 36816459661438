import '@toast-ui/chart/dist/toastui-chart.min.css';
import React from 'react';
import {LineChart, AreaChart} from '@toast-ui/react-chart';
import map from 'lodash/map';
import find from 'lodash/find';

export default class DropOffChartNode extends React.Component {
    constructor(props) {
        super(props);
    }

    getNodeName(nodeId){
        const node = find(this.props.project.nodes, (node) => node.id == parseInt(nodeId));
        return (node) ? node.name : nodeId;
    }

    getViewCountAsPercentage(node){
        const res = node.map(n => {
            return 10;
        })

        // Make sure it starts at 100%;
        return [100].concat(res);
    }

    render(){
        const {data} = this.props;

        if(! data) return null;

        var d = {
            categories: ['10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%'],
            series: map(data.nodes, (node, index)=>{
                return {
                    name: this.getNodeName(index),
                    data: map(node, v=>v)
                }
            })
        };

        var options = {
            chart: {
                width: 1160,
                height: 540,
                title: 'Node Drop Off'
            },
            yAxis: {
                title: 'Total Viewers',
            },
            xAxis: {
                title: '% Of Node Viewed',
                pointOnColumn: true,
                tickInterval: 'auto'
            },
            series: {
                showDot: true,
                zoomable: true
            },
            tooltip: {
                suffix: 'Viewers'
            },
        };

        return(
            <AreaChart
                data={d}
                options={options}
            />
        );
    }
}