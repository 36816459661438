import {useState, useEffect} from 'react'

export function Steps({activeTab, setActiveTab}) {
	const [steps, setSteps] = useState([]);
	const [currentStep, setCurrentStep] = useState(1);

	useEffect(() => {
		setSteps([
			{ name: 'Step 1', href: '#', number: 1 },
			{ name: 'Step 2', href: '#', number: 2 },
			{ name: 'Step 3', href: '#', status: 3 },
			{ name: 'Step 4', href: '#', status: 4 },
		]);
		setCurrentStep(
			Number(activeTab.split('-')[1])
		);
	}, [activeTab])
	return (
	  <nav className="flex items-center justify-start pb-2" aria-label="Progress">
		<p className="text-sm font-medium">
		  Step {currentStep} of {steps.length}
		</p>
		<ol role="list" className="ml-8 flex items-center space-x-5">
		  {steps.map((step) => (
			<li key={step.name}>
			  {step.number <  currentStep ? (
				<a href={step.href} className="block h-2.5 w-2.5 rounded-full bg-blue-600 hover:bg-blue-900">
				  <span className="sr-only">{step.name}</span>
				</a>
			  ) : step.number === currentStep ? (
				<a href={step.href} className="relative flex items-center justify-center" aria-current="step">
				  <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
					<span className="h-full w-full rounded-full bg-blue-200" />
				  </span>
				  <span className="relative block h-2.5 w-2.5 rounded-full bg-blue-600" aria-hidden="true" />
				  <span className="sr-only">{step.name}</span>
				</a>
			  ) : (
				<a href={step.href} className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400">
				  <span className="sr-only">{step.name}</span>
				</a>
			  )}
			</li>
		  ))}
		</ol>
	  </nav>
	)
  }
  