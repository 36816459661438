import React from 'react'
import cx from "classnames";
import styles from "./RatioSelect.module.scss"
import getAsset from "utils/getAsset";

export const RatioSelect = ({heading, onClick, image, selected, value }) => {
    if(! image) image = getAsset('/img/avatar-logo.png');
	const styles = {
		border: '2px solid' + ( selected ? '#41C086' : '#192F54'),
		borderRadius: '15px',
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer'
	} 
	if(heading === "16:9") {
		styles.height = '56.25px';
		styles.width = '100px';
		styles.marginTop = '10px'
	}
	if(heading === "9:16") {
		styles.height = '75px';
		styles.width = '46.25px';
	}
	if(heading === "4:3") {
		styles.height = '56.25px';
		styles.width = '75px';
		styles.marginTop = '10px'
	}
	return (
		<div 
            className={cx(styles.ratioSelect, "clearfix", {[styles.selected] : selected})}
            onClick={() => onClick(value)}
        >
			<div style={styles}>{heading}</div>
			{/* <div>{heading}</div> */}
		</div>
	)
}