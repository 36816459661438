import '@toast-ui/chart/dist/toastui-chart.min.css';
import React from 'react';
import {LineAreaChart} from '@toast-ui/react-chart'
import analytics from 'utils/analytics';

export default class ProjectViewsByDeviceChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            groupBy:'day'
        }
    }

    render(){
        const {data} = this.props;

        const plays_unique = analytics.getSeriesFromQuery(data.plays_unique);
        const plays = analytics.getSeriesFromQuery(data.plays);

        var d = {
            categories: analytics.getCategoriesFromQuery(data.plays, this.state.groupBy),
            series: {
                area: [
                    {
                        name: 'All Views',
                        data: plays
                    }
                ],
                line: [
                    {
                        name: 'Unique Views',
                        data: plays_unique
                    }
                ]
            }
        };
        var options = {
            chart: {
                width: 1160,
                height: 540,
                title: 'Project Views'
            },
            yAxis: {
                title: 'Views',
                min: 0,
                pointOnColumn: true
            },
            xAxis: {
                title: 'Date',
                tickInterval: 'auto'
            },
            series: {
                zoomable: true
            },
            tooltip: {
                //suffix: 'kWh'
            },
            theme: 'myTheme'
        };

        return(
            <LineAreaChart
                data={d}
                options={options}
            />
        );
    }
}