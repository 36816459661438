import { getToken } from "@/modules/auth/utils";
import axios from "axios";
import * as tus from "tus-js-client";
import { useState } from "react";

export function useTusUploader() {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [progress, setProgress] = useState(0);

	const start = async ({ file, type, name, projectId, onComplete }) => {
		try {
			if (type !== "video/mp4") throw new Error("Only mp4 files are supported");
			setLoading(true);
			// First create the new media object in the database and bunny
			const req = await axios({
				method: "POST",
				url: `${import.meta.env.VITE_API_URL}/api/presign/start`,
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
				data: {
					name,
					project_id: projectId,
				},
			});
			const req2 = await axios({
				method: "POST",
				url: `${import.meta.env.VITE_API_URL}/api/presign/signature`,
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
				data: {
					media_id: req.data.media.id,
				},
			});

			// Create a new tus upload
			var upload = new tus.Upload(file, {
				endpoint: "https://video.bunnycdn.com/tusupload",
				retryDelays: [0, 3000, 5000, 10000, 20000],
				headers: {
					AuthorizationSignature: req2.data.signature, // SHA256 signature (library_id + api_key + expiration_time + video_id)
					AuthorizationExpire: req2.data.expires, // Expiration time as in the signature,
					VideoId: req2.data.video_id, // The guid of a previously created video object through the Create Video API call
					LibraryId: req2.data.library_id,
				},
				metadata: {
					filetype: type,
					title: name,
					// collection: "collectionID"
				},
				onError: function (error) {
					console.error(error);
					setError(true);
					setLoading(false);
				},
				onProgress: function (bytesUploaded, bytesTotal) {
					var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
					console.log(bytesUploaded, bytesTotal,  + "%");
					setProgress(percentage);
				},
				onSuccess: async (d) => {
					const req2 = await axios({
						method: "POST",
						url: `${import.meta.env.VITE_API_URL}/api/presign/end`,
						headers: {
							Authorization: `Bearer ${getToken()}`,
						},
						data: {
							media_id: req.data.media.id,
						},
					});
					if(onComplete){
						console.log('Complete');
						console.log(req.data.media);
						await onComplete(req.data.media);
					}
					setLoading(false);
				},
			});

			// Check if there are any previous uploads to continue.
			upload.findPreviousUploads().then(function (previousUploads) {
				// Found previous uploads so we select the first one.
				if (previousUploads.length) {
					upload.resumeFromPreviousUpload(previousUploads[0]);
				}

				// Start the upload
				upload.start();
			});
		} catch (e) {
			setError(e);
			setLoading(false);
		}
	};

	return { start, loading, error, progress, setLoading };
};
