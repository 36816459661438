import React, { useEffect, useState } from "react";
import getAsset from "utils/getAsset";
import styles from "./DashboardSidebar.module.scss";
import { motion } from "framer-motion";

const Logo = ({ whitelabel }) => {
	const animate = {
		opacity: 1,
		bottom: 15,
	};

	const transition = {
		type: "spring",
		duration: 0.3,
		bounce: 0.2,
		damping: 15,
	};

	return (
		<div class="mt-[15px] ">
			{!whitelabel ? (
				<React.Fragment>
					<motion.img
						src={getAsset("/img/logo.png")}
						className={styles.sidebar_logo}
						animate={animate}
						transition={transition}
					/>
					{/* <motion.img
						src="https://s3.us-east-2.amazonaws.com/interactrapp.com/interactr-icon.png"
						className={styles.sidebar_logo_small}
						animate={animate}
						transition={transition}
					/> */}
				</React.Fragment>
			) : (
				<>
					<motion.img
						src={whitelabel.logo}
						className={styles.sidebar_logo}
						animate={animate}
					/>
					{/* <motion.img
						src={whitelabel.icon}
						className={styles.sidebar_logo_small}
						animate={animate}
					/> */}
				</>
			)}
		</div>
	);
};

export default Logo;