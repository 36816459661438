import { DragEvent, useState, useRef } from 'react';
import UploadMediaItem from './UploadMediaItem';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';

export default function FileDrop({accept}) {
  const [dragIsOver, setDragIsOver] = useState(false);
  const [files, setFiles] = useState([]);

  // Define the event handlers
  const handleDragOver = (event) => {
    event.preventDefault();
    setDragIsOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setDragIsOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragIsOver(false);
	const droppedFiles = Array.from(event.dataTransfer.files);
    setFiles(filter(droppedFiles, file => file.type === 'video/mp4'));
  };

  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = event => {
    const filesUploaded = Array.from(event.target.files);
    setFiles(filesUploaded);
  };

  if(files.length) {
	return (
		<div style={{height:'365px', overflowY: 'scroll'}}>
			{files.map((file, index) => <UploadMediaItem file={file}  key={index}/>)}
		</div>
	)
  }

  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
	  onClick={handleClick}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '300px',
        width: '100%',
        border: '1px dotted',
		cursor: 'pointer',
        backgroundColor: dragIsOver ? 'lightgray' : 'white',
      }}
    >
      Drag and drop some files here or click to open file explorer
	  <input
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
		multiple={true}
		accept={accept}
        style={{display: 'none'}} // Make the file input element invisible
      />
    </div>
  );
}
