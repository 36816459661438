import { ProgressBar } from "../ProgressBar";
import {
	PlayIcon,
	CursorArrowRaysIcon,
	ChatBubbleLeftRightIcon,
	HeartIcon,
	ArrowRightIcon
} from "@heroicons/react/24/outline";
import Button from "components/Buttons/Button";
import ProjectMeta from "../ProjectMeta";
import ProjectActions from "../ProjectActions";
import {projectPath} from "../../routes";
import {useNavigate} from 'react-router-dom';

const DEFAULT_ALLOWED_ACTIONS = [
	"copy",
	"delete",
	"changeFolder",
	"edit",
	"preview",
	"share",
];

export const VideoBubbleCard = ({
	project,
	refetchProjects,
	currentImpressions,
	currentPlays,
	previousImpressions,
	previousPlays,
	allowedActions = DEFAULT_ALLOWED_ACTIONS,
}) => {
	console.log(project);
	const navigate = useNavigate();
	const goToProject = () => {
		const path = projectPath({projectId: project.id, library: 'open'});
		navigate(path);
	};
	return (
		<div className="flex group cursor-pointer relative border-2 border-white w-[180px] h-[320px] bg-white rounded-xl shadow-xl overflow-hidden ">
			<img
				src={project.image_url}
				alt={project.title}
				className="w-[180px] h-[320px] rounded-xl absolute top-0 left-0 group-hover:scale-125 transition-transform duration-300 ease-in-out"
			/>
			<div
				className="absolute z-10 bottom-0 left-0 w-[180px] h-[320px] flex flex-col justify-between right-0 py-2"
				style={{
					background: "linear-gradient(transparent, rgba(0, 0, 0, 0.7))",
				}}
			>
				<div className="relative z-50 flex justify-between items-center  px-4 pt-2">
					<div>
						<HeartIcon className="h-5 w-5 text-white" />
					</div>
					<div>
						<ProjectActions
							project={project}
							refetchProjects={refetchProjects}
							allowedActions={allowedActions}
						/>
					</div>
				</div>
				<div class="flex justify-center opacity-0 group-hover:opacity-100">
				<Button primary onClick={goToProject} className="flex">
					Edit Project <ArrowRightIcon className="h-5 w-5 ml-2"/>
				</Button>
				</div>
				<div className="relative z-20  px-2">
					<div className="flex justify-between items-center text-white tracking-wide">
						<h2 className="font-semibold tracking-wide text-lg truncate opacity-[0.9]">
							{project.title}
						</h2>
					</div>
					<div className="flex text-white mt-2">
						<ProjectMeta
							currentImpressions={currentImpressions}
							previousImpressions={previousImpressions}
							currentPlays={currentPlays}
							previousPlays={previousPlays}
							project={project}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
