import { useState } from "react";
import { uploadBase64 } from "utils";

export function useBase64Uploader() {
	const start = async ({ file, type, name, projectId, onComplete }) => {
		const base64String = await fileToBase64(file);
		return await uploadBase64(base64String);
	};

	function fileToBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}

	return { start };
}
