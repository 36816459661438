import React from 'react';
import { useButtonElementCommands } from "../../../../graphql/ButtonElement/hooks";
import { BUTTON_ELEMENT, elements } from "../../elements";
import ElementPropertiesTabs from "../ElementPropertiesTabs";

const ButtonElementProperties = ({element}) => {
  const {updateButtonElement} = useButtonElementCommands(element.id);
  
  const elementMeta = elements[BUTTON_ELEMENT];
  return <ElementPropertiesTabs
    meta={elementMeta}
    element={element}
    update={updateButtonElement}
    startTab={'clickable'}
  />;
};
export default ButtonElementProperties;
