import React, { useState, useEffect} from 'react';
import cx from "classnames";
import styles from "./DynamicTextElementProperties.module.scss";
import Icon from "components/Icon";
import {Option, TextInput} from 'components/PropertyEditor';
import IconButton from 'components/Buttons/IconButton';
import Button from 'components/Buttons/Button';


const DynamicTextElementProperties = ({ element, update }) => {

  const [defaultValues, setDefaultValues] = useState([{id: 1, key: '', value: ''}]);  
  const [duplicate, setDuplicate] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(element?.default_values && element?.default_values.length) {
      setDefaultValues(element?.default_values);
    }
  }, [element])

  const handleSave = () => {
    setLoading(true);
    setTimeout(() => {
      update("default_values", defaultValues);
      setLoading(false);
    }, 500);
  };

  const handleChange = ({property, id}) => (val) => {

    if(property == 'key') {
      const checker = defaultValues.filter(({key}) => {
        return key == val;
      })
      if(checker.length > 0) {
        setDuplicate(true);
      } else {
        setDuplicate(false);
      }
    }

    const temp = defaultValues.map(defaultValue => {
      const _obj = {...defaultValue};
      if(_obj.id === id) {
        _obj[property] = val;
      }
      return _obj;
    });

    setDefaultValues(temp);
  };

  const addDefaultValue = () => {
    let id = !!defaultValues.length && defaultValues.slice(-1).pop().id || 0 ;

    setDefaultValues((prev) => {
      return [...prev, {
        id: ++ id,
        key: '',
        value: ''
      }]
    });
  };

  const removeDefaultValue = defaultValueId => {
    const temp = defaultValues.filter(({id}) => id !== defaultValueId);
    setDefaultValues(temp);
  };

  return (
    <div>
      <h4>Parameters Default Values</h4>
      {
        defaultValues.map(({id, key, value}) => (
          <div className={cx(styles.defaultValueItem, 'grid')} key={'defaultValue_'+ id}>
            <div className={cx(styles.defaultValueKey, 'col5')}>
              <Option
                label={"Key"}
                value={key}
                className={styles.defaultValueInput}
                Component={TextInput}
                onChange={(val) => handleChange({id, property: 'key'})(val)}
              />
            </div>
            <div className="col1">
              <span>
                <Icon name="ellipsis-v"/>
              </span>
            </div>
            <div className={cx(styles.defaultValueValue, 'col5')}>
              <Option
                label={"Value"}
                value={value}
                className={styles.defaultValueInput}
                Component={TextInput}
                onChange={(val) => handleChange({id, property: 'value'})(val)}
              />
            </div>
            <span className={styles.defaultValueEllipsis}>
              <Icon name="times" className={styles.dynamicIconDelete} onClick={() => removeDefaultValue(id)}  />
            </span>
          </div>
        ))
      }

      {/* Footer */}
      <div className={cx(styles.defaultValueItem, 'grid')}>
        <div className="col6">
            <Button primary small onClick={() => handleSave()} disabled={duplicate} loading={loading}>Save</Button>
            { duplicate && <div style={{color: '#cd3232', marginTop: '5px'}}>Keys are duplicated!</div>}
        </div>
        <div className="col6">
            <IconButton
                icon="plus"
                primary
                small="true"
                onClick={() => addDefaultValue()}
                buttonStyles={{float: 'right'}}
            />
        </div>
      </div>
    </div>
  );
}

export default DynamicTextElementProperties;