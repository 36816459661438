import PropTypes from "prop-types";
import React, { useRef } from "react";
import Player from "react-player";
import Icon from "components/Icon";

const props = {
	url: PropTypes.string,
	videoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	imageURL: PropTypes.string,
	// ... and all the supported react-player props https://github.com/CookPete/react-player
};
export default function VideoPlayer({
	videoId,
	url,
	imageURL,
	controls,
	...restOfProps
}) {
	const videoRef = useRef(null);
	const [playing, setPlaying] = React.useState(false);
	const playVideo = () => {
		console.log(videoRef);
		if (videoRef.current) {
			setPlaying(true);
			videoRef.current.play();
		}
	};
	return (
		<div className="embed-responsive">
			<div className="embed-responsive-item">
				{imageURL && !playing && (
					<div>
						<div style={{position: 'absolute', top: '55px', left: '110px', right: 'auto'}}>
						<Icon
							name="play-circle"
							size={"3x"}
							color={'#fff'}
							onClick={playVideo}
						/>
						</div>
						<img
							src={imageURL}
							className={"img-fluid"}
							onClick={playVideo} // Step 3: Add onClick event to play video
							style={{ cursor: "pointer" }}
						/>
					</div>
				)}
				<video
					key={videoId}
					ref={videoRef} // Step 2: Attach ref to video element
					width={"100%"}
					height={"100%"}
					controls={controls}
					playsInline
					preload="metadata"
				>
					<source src={url} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			</div>
		</div>
	);
}
VideoPlayer.propTypes = props;
