import React from 'react';
import Styles from './TrainingVideo.module.scss';

export default class TrainingVideo extends React.Component {
  getPlayLink = (link) => {
    const split = link.split('/');
    return 'https://player.vimeo.com/video/'+split[3];
  };

  render(){
    const {video} = this.props;
    //const link = this.getPlayLink(video.link);
    
    return(
      <div className={Styles.wrapper}>
        <h3 className="faded-heading">{video.name}</h3>
		<div style={{position:'relative', paddingTop:'56.25%'}}>
        <iframe src={ video.link + '?autoplay=false&loop=false&muted=false&preload=true'} loading="lazy" 
		style={{border:'0', position: 'absolute' , top: '0' , height:'100%', width:'100%'}} allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe>
		</div>
        <p>{video.description}</p>
      </div>
    )
  }
}