import React from "react";
import PropTypes from "prop-types";
import { useReactiveVar } from "@apollo/client";
import _map from "lodash/map";
import { AnimateSharedLayout, motion } from "framer-motion";

import { ReplaceMediaSourceModal } from "./ReplaceMediaSourceModal";
import { MediaCard } from "./MediaCard";
import MediaSidebarCard from "./MediaSidebarCard";
import { useSetState } from "utils/hooks";
import {
	setEditMedia,
	SHOW_EDIT_MEDIA_MODAL,
	SHOW_REPLACE_MEDIA_MODAL,
} from "@/graphql/LocalState/editMedia";

import {
	getNodeSettings,
	setNodeSettings,
	SHOW_CHANGE_SOURCE_MEDIA_MODAL,
} from "@/graphql/LocalState/nodeSettings";

import {
	setAddMedia,
	SHOW_UPLOAD_TYPE_SELECT_MODAL,
} from "@/graphql/LocalState/addMedia";

const listAnimations = {
	initial: {
		opacity: 1,
		y: 0,
		scale: 1,
		transition: { type: "ease-in" },
	},
	animate: {
		opacity: 1,
		y: 0,
		transition: { type: "ease-in", duration: 0.8 },
	},
};

const animantions = {
	hidden: { opacity: 0 },
	show: {
		opacity: 1,
		transition: {
			staggerChildren: 0.2,
		},
	},
};
/**
 * Abstract component for multiple usage on app
 * Ex. Videos Page, Media library side on canvas page
 * @param items
 * @param librarySidebar
 * @returns {*}
 * @constructor
 */
export const MediaCards = ({ items, librarySidebar = false, refetch }) => {
	const { activeModal } = useReactiveVar(getNodeSettings);

	const [state, setState] = useSetState({
		showEditModal: false,
		showReplaceSourceModal: false,
		selectedMedia: null,
	});
	const { showReplaceSourceModal, selectedMedia } = state;
	const ulStyles = librarySidebar ? { padding: 0 } : {};

	const replaceMediaSource = (media) => {
		setAddMedia({
			activeModal: SHOW_UPLOAD_TYPE_SELECT_MODAL,
			previousModals: [], // clear this incase we have any old data in there
			newMediaObject: {
				// project_id: parseInt(media?.project_id),
				id: media?.id,
				manifest_url: "",
			},
			projectRatio: media?.media_size,
			replaceMedia: true,
		});

		// Check if NodeSettings workflow has any active modals
		// If so, add NodeSourceMediaModal to the previous modals list
		// So we can come back to the NodeSettings workflow
		if (activeModal) {
			setNodeSettings({
				activeModal: "",
			});
			setAddMedia({
				previousModals: [SHOW_CHANGE_SOURCE_MEDIA_MODAL],
			});
		}
	};

	if (librarySidebar) {
		return (
			<>
				<AnimateSharedLayout>
					<motion.div initial="hidden" animate="show" variants={animantions}>
						{_map(items, (item) => (
							<motion.ul
								key={item.id}
								style={ulStyles}
								{...listAnimations}
								className=""
							>
								<MediaSidebarCard
									media={item}
									onUpdate={() => {
										setEditMedia({
											activeModal: SHOW_EDIT_MEDIA_MODAL,
											media: item,
										});
									}}
									replaceMediaSource={() => replaceMediaSource(item)}
								/>
							</motion.ul>
						))}
					</motion.div>
				</AnimateSharedLayout>
			</>
		);
	}
	return (
		<div class="">
			<AnimateSharedLayout>
				<motion.div initial="hidden" animate="show" className="flex flex-wrap" variants={animantions}>
					{_map(items, (item) => (
						<motion.div
							key={item.id}
							style={ulStyles}
							{...listAnimations}
							className="w-[300px]"
						>
							<MediaCard
								refetch={refetch}
								media={item}
								librarySidebar={librarySidebar}
								onUpdate={() => {
									setEditMedia({
										activeModal: SHOW_EDIT_MEDIA_MODAL,
										media: item,
									});
								}}
								replaceMediaSource={() => replaceMediaSource(item)}
							/>
						</motion.div>
					))}
				</motion.div>
			</AnimateSharedLayout>

			{/* <ReplaceMediaSourceModal
				show={showReplaceSourceModal}
				media={selectedMedia}
				closeModal={() => {
					setState({
						selectedMedia: null,
						showReplaceSourceModal: false,
					});
				}}
			/> */}
		</div>
	);
};

MediaCards.propTypes = {
	items: PropTypes.array.isRequired,
	librarySidebar: PropTypes.bool,
	refetch: PropTypes.func.isRequired,
};
