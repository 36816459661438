import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import { Option, TextInput } from "components/PropertyEditor";
import Button from "components/Buttons/Button";
import {useSetState} from "utils/hooks";
import { useProjectCommands } from "@/graphql/Project/hooks";

const QUERY = gql`
	query project($id: ID!) {
		project(id: $id) {
			id
			fbPixelId
		}
	}
`;

export const ProjectFacebookPixel = () => {
	const { projectId } = useParams();
	const [state, setState] = useSetState({
		saving: false,
		fbPixelId: "",
	});
	const {fbPixelId, saving} = state;
	const {saveProject} = useProjectCommands();

	const { data, loading, error } = useQuery(QUERY, {
		variables: {
			id: parseInt(projectId),
		},
	});

	const handleSave = async () => {
		try {
			setState({ saving: true });
			await saveProject({
				variables: {
					input: {
						id: projectId,
						fbPixelId: fbPixelId,
					},
				},
			});
		} catch (err) {
			console.error(err);
		} finally {
			setState({ saving: false });
		}
	};

	useEffect(() => {
		if (data) {
			setState({ fbPixelId: data.project.fbPixelId });
		}
	}, [data]);

	if (loading || error) return null;

	
	return (
		<div style={{ display: "flex", alignItems: "center" }}>
			<div style={{ flex: 1 }}>
				<Option
					label="Project Facebook Pixel"
					name="name"
					value={fbPixelId}
					disabled={saving}
					Component={TextInput}
					onChange={val => setState({ fbPixelId: val })}
					onEnter={handleSave}
				/>
			</div>
			<div style={{ marginLeft: "20px" }}>
				<Button primary icon={"save"} onClick={handleSave} disabled={saving}>
					Save
				</Button>
			</div>
		</div>
	);
};
