import React from 'react';
import { motion } from 'framer-motion';
import ContentLoader from 'react-content-loader';
import times from 'lodash/times';

import { tabAnimation } from './index';

export const MediaLibraryLoading = () => {
	return (
		<motion.div {...tabAnimation} className="flex flex-wrap gap-8 max-w-7xl px-4 ">
			{times(30, () => (
				<div className='bg-gray-200 animate-pulse h-[151px] w-[268px] rounded-xl'>
					&nbsp;
				</div>
			))}
		</motion.div>
	);
};
