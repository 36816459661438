export default [
  {name: 'Shape Top Left', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/1-01.png'},
  {name: 'Shape Bottom Right', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/2-01-01.png'},
  {name: 'Facebook', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/Facebook.png'},
  {name: 'Arrow', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/Flecha_01.png'},
  {name: 'Instagram', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/Instagram.png'},
  {name: 'Speech Bubble', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/QUESTIONBUBBLE.png'},
  {name: 'Snapchat', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/Snapchat.png'},
  {name: 'Twitter', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/Twitter.png'},
  {name: 'WhatsApp', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/WhatsApp.png'},
  {name: 'YouTube', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/YouTube.png'},
  {name: 'Americano', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/americano.png'},
  {name: 'Banner 2', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/banner2.png'},
  {name: 'Blue Banner', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/blue.png'},
  {name: 'Book Icon', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/book.png'},
  {name: 'Boy Break Dance', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/boy.png'},
  {name: 'Red Banner', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/darkred.png'},
  {name: 'Money Chart', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/economics.png'},
  {name: 'White Arrow', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/white-arrow.png'},
  {name: 'Macbook Video Icon', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/videoconference.png'},
  {name: 'Sale Background', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/sale-background.png'},
  {name: 'Ribbon', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/ribbon.png'},
  {name: 'White Ribbon', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/ribbon-WHITE.png'},
  {name: 'Orange Banner', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/red.png'},
  {name: 'Question Mark', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/question-mark.png'},
  {name: 'Divider', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/line.png'},
  {name: 'Latte', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/latte+copy.png'},
  {name: 'Green Banner', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/green.png'},
  {name: 'Girl', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/girl.png'},
  {name: 'eCom', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/girl-983969_640.jpg'},
  {name: 'Whats New Gif', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/ezgif-2-1563f7ccb0ff.gif'},
  {name: 'Tell Me More Gif', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/ezgif-2-0a9c6f79712b.gif'},
  {name: 'Expresso', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/expresso+copy.png'},
  {name: 'Sales Chart', src:'https://s3.us-east-2.amazonaws.com/static.videosuite.io/interactr/images/economics.png'},


  {name: 'YES - black', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/YES/YES+-+black.png'},
  {name: 'YES - blue', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/YES/YES+-+blue.png'},
  {name: 'YES - green', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/YES/YES+-+green.png'},
  {name: 'YES - orange', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/YES/YES+-+orange.png'},
  {name: 'YES - purple', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/YES/YES+-+purple.png'},
  {name: 'YES - red', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/YES/YES+-+red.png'},
  {name: 'YES - white', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/YES/YES+-+white.png'},
  {name: 'TELL ME MORE - black', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/TELL+ME+MORE/TELL+ME+MORE+-+black.png'},
  {name: 'TELL ME MORE - blue', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/TELL+ME+MORE/TELL+ME+MORE+-+blue.png'},
  {name: 'TELL ME MORE - green', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/TELL+ME+MORE/TELL+ME+MORE+-+green.png'},
  {name: 'TELL ME MORE - orange', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/TELL+ME+MORE/TELL+ME+MORE+-+orange.png'},
  {name: 'TELL ME MORE - purple', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/TELL+ME+MORE/TELL+ME+MORE+-+purple.png'},
  {name: 'TELL ME MORE - red', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/TELL+ME+MORE/TELL+ME+MORE+-+red.png'},
  {name: 'TELL ME MORE - white', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/TELL+ME+MORE/TELL+ME+MORE+-+white.png'},
  {name: 'SUPPORT - black', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/SUPPORT/SUPPORT+-+black.png'},
  {name: 'SUPPORT - blue', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/SUPPORT/SUPPORT+-+blue.png'},
  {name: 'SUPPORT - green', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/SUPPORT/SUPPORT+-+green.png'},
  {name: 'SUPPORT - orange', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/SUPPORT/SUPPORT+-+orange.png'},
  {name: 'SUPPORT - purple', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/SUPPORT/SUPPORT+-+purple.png'},
  {name: 'SUPPORT - red', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/SUPPORT/SUPPORT+-+red.png'},
  {name: 'SUPPORT - white', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/SUPPORT/SUPPORT+-+white.png'},
  {name: 'SKIP - black', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/SKIP/SKIP+-+black.png'},
  {name: 'SKIP - blue', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/SKIP/SKIP+-+blue.png'},
  {name: 'SKIP - green', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/SKIP/SKIP+-+green.png'},
  {name: 'SKIP - orange', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/SKIP/SKIP+-+orange.png'},
  {name: 'SKIP - purple', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/SKIP/SKIP+-+purple.png'},
  {name: 'SKIP - red', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/SKIP/SKIP+-+red.png'},
  {name: 'SKIP - white', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/SKIP/SKIP+-+white.png'},
  {name: 'SHOW ME - black', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/SHOW+ME/SHOW+ME+-+black.png'},
  {name: 'SHOW ME - blue', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/SHOW+ME/SHOW+ME+-+blue.png'},
  {name: 'SHOW ME - green', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/SHOW+ME/SHOW+ME+-+green.png'},
  {name: 'SHOW ME - orange', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/SHOW+ME/SHOW+ME+-+orange.png'},
  {name: 'SHOW ME - purple', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/SHOW+ME/SHOW+ME+-+purple.png'},
  {name: 'SHOW ME - red', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/SHOW+ME/SHOW+ME+-+red.png'},
  {name: 'SHOW ME - white', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/SHOW+ME/SHOW+ME+-+white.png'},
  {name: 'OK - black', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/OK/OK+-+black.png'},
  {name: 'OK - blue', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/OK/OK+-+blue.png'},
  {name: 'OK - green', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/OK/OK+-+green.png'},
  {name: 'OK - orange', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/OK/OK+-+orange.png'},
  {name: 'OK - purple', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/OK/OK+-+purple.png'},
  {name: 'OK - red', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/OK/OK+-+red.png'},
  {name: 'OK - white', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/OK/OK+-+white.png'},
  {name: 'OF COURSE - blue', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/OF+COURSE/OF+COURSE+-+blue.png'},
  {name: 'OF COURSE - green', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/OF+COURSE/OF+COURSE+-+green.png'},
  {name: 'OF COURSE - orange', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/OF+COURSE/OF+COURSE+-+orange.png'},
  {name: 'OF COURSE - purple', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/OF+COURSE/OF+COURSE+-+purple.png'},
  {name: 'OF COURSE - red', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/OF+COURSE/OF+COURSE+-+red.png'},
  {name: 'OF COURSE - white', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/OF+COURSE/OF+COURSE+-+white.png'},
  {name: 'NOT SURE - black', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/NOT+SURE/NOT+SURE+-+black.png'},
  {name: 'NOT SURE - blue', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/NOT+SURE/NOT+SURE+-+blue.png'},
  {name: 'NOT SURE - green', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/NOT+SURE/NOT+SURE+-+green.png'},
  {name: 'NOT SURE - orange', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/NOT+SURE/NOT+SURE+-+orange.png'},
  {name: 'NOT SURE - purple', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/NOT+SURE/NOT+SURE+-+purple.png'},
  {name: 'NOT SURE - red', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/NOT+SURE/NOT+SURE+-+red.png'},
  {name: 'NOT SURE - white', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/NOT+SURE/NOT+SURE+-+white.png'},
  {name: 'NO - black', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/NO/NO+-+black.png'},
  {name: 'NO - blue', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/NO/NO+-+blue.png'},
  {name: 'NO - green', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/NO/NO+-+green.png'},
  {name: 'NO - orange', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/NO/NO+-+orange.png'},
  {name: 'NO - purple', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/NO/NO+-+purple.png'},
  {name: 'NO - red', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/NO/NO+-+red.png'},
  {name: 'NO - white', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/NO/NO+-+white.png'},
  {name: 'NO WAY - black', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/NO+WAY/NO+WAY+-+black.png'},
  {name: 'NO WAY - blue', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/NO+WAY/NO+WAY+-+blue.png'},
  {name: 'NO WAY - green', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/NO+WAY/NO+WAY+-+green.png'},
  {name: 'NO WAY - orange', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/NO+WAY/NO+WAY+-+orange.png'},
  {name: 'NO WAY - purple', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/NO+WAY/NO+WAY+-+purple.png'},
  {name: 'NO WAY - red', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/NO+WAY/NO+WAY+-+red.png'},
  {name: 'NO WAY - white', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/NO+WAY/NO+WAY+-+white.png'},
  {name: 'MENU - black', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/MENU/MENU+-+black.png'},
  {name: 'MENU - blue', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/MENU/MENU+-+blue.png'},
  {name: 'MENU - green', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/MENU/MENU+-+green.png'},
  {name: 'MENU - orange', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/MENU/MENU+-+orange.png'},
  {name: 'MENU - purple', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/MENU/MENU+-+purple.png'},
  {name: 'MENU - red', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/MENU/MENU+-+red.png'},
  {name: 'MENU - white', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/MENU/MENU+-+white.png'},
  {name: 'MAKE IT SNAPPY - black', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/MAKE+IT+SNAPPY/MAKE+IT+SNAPPY+-+black.png'},
  {name: 'MAKE IT SNAPPY - blue', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/MAKE+IT+SNAPPY/MAKE+IT+SNAPPY+-+blue.png'},
  {name: 'MAKE IT SNAPPY - green', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/MAKE+IT+SNAPPY/MAKE+IT+SNAPPY+-+green.png'},
  {name: 'MAKE IT SNAPPY - orange', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/MAKE+IT+SNAPPY/MAKE+IT+SNAPPY+-+orange.png'},
  {name: 'MAKE IT SNAPPY - purple', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/MAKE+IT+SNAPPY/MAKE+IT+SNAPPY+-+purple.png'},
  {name: 'MAKE IT SNAPPY - red', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/MAKE+IT+SNAPPY/MAKE+IT+SNAPPY+-+red.png'},
  {name: 'MAKE IT SNAPPY - white', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/MAKE+IT+SNAPPY/MAKE+IT+SNAPPY+-+white.png'},
  {name: 'LEARN MORE - black', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/LEARN+MORE/LEARN+MORE+-+black.png'},
  {name: 'LEARN MORE - blue', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/LEARN+MORE/LEARN+MORE+-+blue.png'},
  {name: 'LEARN MORE - green', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/LEARN+MORE/LEARN+MORE+-+green.png'},
  {name: 'LEARN MORE - orange', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/LEARN+MORE/LEARN+MORE+-+orange.png'},
  {name: 'LEARN MORE - purple', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/LEARN+MORE/LEARN+MORE+-+purple.png'},
  {name: 'LEARN MORE - red', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/LEARN+MORE/LEARN+MORE+-+red.png'},
  {name: 'LEARN MORE - white', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/LEARN+MORE/LEARN+MORE+-+white.png'},
  {name: 'GO BACK - black', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/GO+BACK/GO+BACK+-+black.png'},
  {name: 'GO BACK - blue', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/GO+BACK/GO+BACK+-+blue.png'},
  {name: 'GO BACK - green', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/GO+BACK/GO+BACK+-+green.png'},
  {name: 'GO BACK - orange', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/GO+BACK/GO+BACK+-+orange.png'},
  {name: 'GO BACK - purple', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/GO+BACK/GO+BACK+-+purple.png'},
  {name: 'GO BACK - red', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/GO+BACK/GO+BACK+-+red.png'},
  {name: 'GO BACK - white', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/GO+BACK/GO+BACK+-+white.png'},
  {name: 'CALL NOW - black', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/CALL+NOW/CALL+NOW+-+black.png'},
  {name: 'CALL NOW - blue', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/CALL+NOW/CALL+NOW+-+blue.png'},
  {name: 'CALL NOW - green', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/CALL+NOW/CALL+NOW+-+green.png'},
  {name: 'CALL NOW - orange', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/CALL+NOW/CALL+NOW+-+orange.png'},
  {name: 'CALL NOW - purple', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/CALL+NOW/CALL+NOW+-+purple.png'},
  {name: 'CALL NOW - red', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/CALL+NOW/CALL+NOW+-+red.png'},
  {name: 'CALL NOW - white', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/CALL+NOW/CALL+NOW+-+white.png'},
  {name: 'ADD TO CART - blue', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/ADD+TO+CART/ADD+TO+CART+-+blue.png'},
  {name: 'ADD TO CART - green', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/ADD+TO+CART/ADD+TO+CART+-+green.png'},
  {name: 'ADD TO CART - orange', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/ADD+TO+CART/ADD+TO+CART+-+orange.png'},
  {name: 'ADD TO CART - purple', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/ADD+TO+CART/ADD+TO+CART+-+purple.png'},
  {name: 'ADD TO CART - red', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/ADD+TO+CART/ADD+TO+CART+-+red.png'},
  {name: 'ADD TO CART - white', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/ADD+TO+CART/ADD+TO+CART+-+white.png'},
  {name: 'ADD TO CART - black', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/06+June/ADD+TO+CART/ADD+TO+CART+-+black.png'},

  {name: 'Click here to escape now', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/May/Click+here+to+escape+now.png'},
  {name: 'keep going', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/May/keep+going.png'},
  {name: 'keep it wild', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/May/keep+it+wild.png'},
  {name: 'mountain biking', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/May/mountain+biking.png'},
  {name: 'river kayaking', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/May/river+kayaking.png'},
  {name: 'rock crawling', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/May/rock+crawling.png'},
  {name: 'turn on tv', src:'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/May/turn+on+tv.png'},
  
  {name: 'Access Now-a', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/Access+Now-a.png'},
  {name: 'Access Now-b', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/Access+Now-b.png'},
  {name: 'Add To Cart-a', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/Add+To+Cart-a.png'},
  {name: 'Add To Cart-b', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/Add+To+Cart-b.png'},
  {name: 'Buy Now-a', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/Buy+Now-a.png'},
  {name: 'Buy Now-b', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/Buy+Now-b.png'},
  {name: 'Call Now-a', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/Call+Now-a.png'},
  {name: 'Call Now-b', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/Call+Now-b.png'},
  {name: 'Go Back-b', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/Go+Back-b.png'},
  {name: 'Go Back-w', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/Go+Back-w.png'},
  {name: 'Learn More-a', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/Learn+More-a.png'},
  {name: 'Learn More-b', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/Learn+More-b.png'},
  {name: 'Need More Info-b', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/Need+More+Info-b.png'},
  {name: 'Need More Info-w', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/Need+More+Info-w.png'},
  {name: 'Next-b', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/Next-b.png'},
  {name: 'Next-w', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/Next-w.png'},
  {name: 'No Way-b', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/No+Way-b.png'},
  {name: 'No Way-w', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/No+Way-w.png'},
  {name: 'Not Sure-b', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/Not+Sure-b.png'},
  {name: 'Not Sure-w', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/Not+Sure-w.png'},
  {name: 'OK-b', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/OK-b.png'},
  {name: 'OK-w', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/OK-w.png'},
  {name: 'Of Course-b', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/Of+Course-b.png'},
  {name: 'Of Course-w', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/Of+Course-w.png'},
  {name: 'Show Me-b', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/Show+Me-b.png'},
  {name: 'Show Me-w', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/Show+Me-w.png'},
  {name: 'Support-b', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/Support-b.png'},
  {name: 'Support-w', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/Support-w.png'},
  {name: 'skip-b', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/skip-b.png'},
  {name: 'skip-w', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/april/skip-w.png'},

  {name: 'Blue Gif', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/march/blue.gif'},
  {name: 'Green Gif', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/march/green.gif'},
  {name: 'Grey Gif', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/march/grey.gif'},
  {name: 'Orange Gif', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/march/orange.gif'},
  {name: 'Red Gif', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/march/red.gif'},
  {name: 'White Gif', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/march/white.gif'},
  {name: 'Yellow Gif', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/march/yellow.gif'},
  {name: 'Check Out The Bonuses', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/march/check+out+the+bonuses+1.png'},
  {name: 'Check Out The Bonuses 2', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/march/check+out+the+bonuses+2.png'},
  {name: 'Make It Snappy 1', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/march/make+it+snappy+1.png'},
  {name: 'Make It Snappy 2', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/march/make+is+snappy+2.png'},
  {name: 'No 1', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/march/no+1.png'},
  {name: 'No 2', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/march/no+2.png'},
  {name: 'Yes 1', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/march/yes+1.png'},
  {name: 'Yes 2', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/march/yes+2.png'},
  {name: 'Tell Me More 1', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/march/tell+me+more+1.png'},
  {name: 'Tell Me More 2', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/march/tell+me+more+2.png'},
  {name: 'Watch The Demo', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/march/watch+the+demo+1.png'},


  {name: 'Tell Me More', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/svmore.png'},
  {name: 'Make It Snappy', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/svsnappy.png'},
  {name: 'Black T-Shirt', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/black.png'},
  {name: 'Green T-Shirt', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/green.png'},
  {name: 'Pink T-Shirt', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/pink.png'},
  {name: 'Pick Another T-Shirt', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/pickanother.png'},
  {name: 'Grab a Coffee', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/coffee.png'},
  {name: 'Go to Office', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/office.png'},
  {name: 'Play Again', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/play%2Bagain.png'},
  {name: 'Snooze', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/snooze.png'},
  {name: 'Wake Up', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/wake%2Bup+(1).png'},
  {name: 'Stop', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/stop.png'},
  {name: 'Tick', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/ok-512.png'},
  {name: 'Next', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/noun_892104_cc.png'},
  {name: 'Businessman', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/businessman-607834_640.png'},
  {name: 'Bricks Background', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/bricks-459299_1920.jpg'},
  {name: 'Facebook', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/facebook.png'},
  {name: 'Google+', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/google%2B.png'},
  {name: 'Pintrest', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/pintrest.png'},
  {name: 'Twitter', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/twitter.png'},
  {name: 'Instagram', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/instagram.png'},
  {name: 'Check Out The Bonuses 1', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/1/check+out+the+bonuses.png'},
  {name: 'Make It Snappy 1', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/1/make+it+snappy.png'},
  {name: 'Tell Me More 1', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/1/tell+me+more.png'},
  {name: 'Watch The Demo 1', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/1/watch+the+demo.png'},
  {name: 'No 1', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/1/no.png'},
  {name: 'Yes 1', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/1/yes.png'},
  {name: 'Check Out The Bonuses 2', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/2/check+out+the+bonuses.png'},
  {name: 'Make It Snappy 2', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/2/make+it+snappy.png'},
  {name: 'Tell Me More 2', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/2/tell+me+more.png'},
  {name: 'Watch The Demo 2', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/2/watch+the+demo.png'},
  {name: 'No 2', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/2/no.png'},
  {name: 'Yes 2', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/2/yes.png'},
  {name: 'Check Out The Bonuses 3', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/3/check+out+the+bonuses.png'},
  {name: 'Make It Snappy 3', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/3/make+it+snappy.png'},
  {name: 'Tell Me More 3', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/3/tell+me+more.png'},
  {name: 'Watch The Demo 3', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/3/watch+the+demo.png'},
  {name: 'No 3', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/3/no.png'},
  {name: 'Yes 3', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/3/yes.png'},
  {name: 'Check Out The Bonuses 4', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/4/check+out+the+bonuses.png'},
  {name: 'Make It Snappy 4', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/4/make+it+snappy.png'},
  {name: 'Tell Me More 4', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/4/tell+me+more.png'},
  {name: 'Watch The Demo 4', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/4/watch+the+demo.png'},
  {name: 'No 4', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/4/no.png'},
  {name: 'Yes 4', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/4/yes.png'},
  {name: 'Check Out The Bonuses 5', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/5/check+out+the+bonuses.png'},
  {name: 'Make It Snappy 5', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/5/make+it+snappy.png'},
  {name: 'Tell Me More 5', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/5/tell+me+more.png'},
  {name: 'Watch The Demo 5', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/5/watch+the+demo.png'},
  {name: 'No 5', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/5/no.png'},
  {name: 'Yes 5', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/5/yes.png'},
  {name: 'Check Out The Bonuses 6', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/6/check+out+the+bonuses.png'},
  {name: 'Make It Snappy 6', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/6/make+it+snappy.png'},
  {name: 'Tell Me More 6', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/6/tell+me+more.png'},
  {name: 'Watch The Demo 6', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/6/watch+the+demo.png'},
  {name: 'No 6', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/6/no.png'},
  {name: 'Yes 6', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/6/yes.png'},
  {name: 'Check Out The Bonuses 7', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/7/check+out+the+bonuses.png'},
  {name: 'Make It Snappy 7', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/7/make+it+snappy.png'},
  {name: 'Tell Me More 7', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/7/tell+me+more.png'},
  {name: 'Watch The Demo 7', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/7/watch+the+demo.png'},
  {name: 'No 7', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/7/no.png'},
  {name: 'Yes 7', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/7/yes.png'},
  {name: 'Check Out The Bonuses 8', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/8/check+out+the+bonuses.png'},
  {name: 'Make It Snappy 8', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/8/make+it+snappy.png'},
  {name: 'Tell Me More 8', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/8/tell+me+more.png'},
  {name: 'Watch The Demo 8', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/8/watch+the+demo.png'},
  {name: 'No 8', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/8/no.png'},
  {name: 'Yes 8', src: 'https://s3.us-east-2.amazonaws.com/cdn6.swiftcdn.co/stockImages/buttons/8/yes.png'},

  
];
