import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { useQuery } from "@apollo/client";
import { getAsset } from "utils";
import { Icon } from "components";
import { confirm, errorAlert } from "utils/alert";
import { useDeleteMedia } from "@/graphql/Media/hooks";
import { MediaViews } from "./MediaViews";
import gql from "graphql-tag";

/**
 * Single media item card
 * @param media
 * @param onUpdate
 * @param onDelete
 * @param replaceMediaSource
 * @param deleting
 * @returns {*}
 * @constructor
 */

const QUERY = gql`
	query project($id: ID!) {
		project(id: $id) {
			id
			title
		}
	}
`;

export const MediaCard = ({ media, onUpdate, replaceMediaSource, refetch }) => {
	const [deleteMedia, { loading: deleting, error: deleteError }] =
		useDeleteMedia();

	const { data, loading, error } = useQuery(QUERY, {
		variables: { id: media.project_id },
	});

	const confirmDeleteMedia = (media) => {
		confirm({
			title: "Are You Sure!",
			text: "Are You Sure You Want To Delete This Media?",
			confirmButtonText: "Yes, Delete It!",
			onConfirm: async () => {
				try {
					await deleteMedia(null, media.id);
					await refetch();
				} catch (err) {
					// TODO set static error while can figure out how to get exact BE error message
					errorAlert({
						text: "Can't delete media item. It is in use for other nodes.",
					});
				}
			},
		});
	};

	let thumbnail_url = media.thumbnail_url || getAsset("/img/no-thumbnail.png");
	if (thumbnail_url.includes("b-cdn.net") && !media.is_image) {
		thumbnail_url = thumbnail_url.replace("thumbnail.jpg", "preview.webp");
	}
	
	return (
		<div className="px-4">
			<ReactTooltip className="tooltip" />
			<div className="relative">
				<img src={thumbnail_url} onClick={onUpdate} className="w-full cursor-pointer rounded-xl shadow-xl " />
				<div className="absolute bottom-2 right-2 bg-[#192F54] text-white rounded-lg px-2 py-0.5 shadow-lg">
					<p className="">
						<Icon name="eye" />
						<MediaViews id={media.id} />
					</p>
				</div>
			</div>

			<div className="mt-2 px-2">
				<h2
					className="text-sm text-blue-500 cursor-pointer truncate block w-[260px] font-semibold"
					onClick={onUpdate}
				>
					{media.name}
				</h2>
				<MetaRow media={media} />
				<div className="flex justify-between mt-1">
					<p className="text-sm">Project:&nbsp;<strong className=" font-semibold">{data?.project?.title}</strong></p>
				</div>
				<div className="flex text-right mb-8 mt-1">
					<span
						style={{ cursor: "pointer", marginRight: "15px" }}
						data-tip={"Rename"}
					>
						<Icon name={"edit"} onClick={onUpdate} />
					</span>
					<span
						style={{ cursor: "pointer", marginRight: "15px" }}
						data-tip={"Replace Media"}
					>
						<Icon name={"sync"} onClick={replaceMediaSource} />
					</span>
					<span
						style={{ cursor: "pointer" }}
						data-tip={"Delete"}
						onClick={() => confirmDeleteMedia(media)}
					>
						{deleting ? <Icon loading /> : <Icon name={"trash-alt"} />}
					</span>
				</div>
			</div>
		</div>
	);
};

MediaCard.propTypes = {
	media: PropTypes.object.isRequired,
	onUpdate: PropTypes.func.isRequired,
	replaceMediaSource: PropTypes.func.isRequired,
	refetch: PropTypes.func.isRequired,
};

const MetaRow = ({ media }) => {
	const type = media.is_image ? "Image" : "Video";

	return (
		<small style={{ marginTop: "10px", opacity: 0.8 }}>
			{type} / Created {moment.utc(media.created_at).fromNow()}
		</small>
	);
};

MetaRow.propTypes = {
	media: PropTypes.object.isRequired,
};

export default MediaCard;
