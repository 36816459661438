import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useAuthUser, useSaveUser } from '@/graphql/User/hooks';
import axios from 'axios';

export default function useAweberAuth() {
	const CLIENT_ID = import.meta.env.VITE_AWEBER_CLIENT_ID;
	const REDIRECT_URI = import.meta.env.VITE_AWEBER_REDIRECT_URI;
	const STATE = import.meta.env.VITE_AWEBER_STATE;
	const SCOPE = ['account.read', 'list.read', 'subscriber.write'].join(' ');
	const APP_URL = import.meta.env.VITE_SHARE_APP_URL;

	const aweberAuthUrl = `https://auth.aweber.com/oauth2/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=${SCOPE}&state=${STATE}`;

	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const code = searchParams.get('code');
	const state = searchParams.get('state');

	const user = useAuthUser();
	const [saveUser] = useSaveUser();

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (state === STATE) {
			fetchToken(code);
		}
	}, [code, state]);

	const fetchToken = (code) => {
		setLoading(true);

		axios
			.get(`${APP_URL}/api/aweber/token`, {
				params: {
					grant_type: 'authorization_code',
					code,
				},
			})
			.then(async ({ data: { refresh_token } }) => {
				await saveUser({
					id: user.id,
					integration_aweber: {
						refresh_token,
					},
				});

				setLoading(false);
				navigate('.');
			});
	};

	return {
		loading,
		aweberAuthUrl,
	};
}
