const process = import.meta;

import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
// import Honeybadger from 'honeybadger-js'
// import ErrorBoundary from 'honeybadger-io'
import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react'
import "./index.scss";
import './index.css'

const config = {
    apiKey: 'eeff9826',
    environment: 'production',
}

const honeybadger = Honeybadger.configure(config)

const container = document.getElementById("appRoot");
const root = createRoot(container);
root.render(
	<HoneybadgerErrorBoundary honeybadger={honeybadger}>
		<App tab="home" />
	</HoneybadgerErrorBoundary>
);
