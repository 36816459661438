export default [
    {
        name: 'Video One',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam et nulla hendrerit, sollicitudin ex non, vestibulum tellus.',
        link: 'https://vimeo.com/253278852',
    },
    {
        name: 'Video Two',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam et nulla hendrerit, sollicitudin ex non, vestibulum tellus.',
        link: 'https://vimeo.com/253278852',
    },
    {
        name: 'Video Three',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam et nulla hendrerit, sollicitudin ex non, vestibulum tellus.',
        link: 'https://vimeo.com/253278852',
    },
    {
        name: 'Video Four',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam et nulla hendrerit, sollicitudin ex non, vestibulum tellus.',
        link: 'https://vimeo.com/253278852',
    },
    {
        name: 'Video Five',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam et nulla hendrerit, sollicitudin ex non, vestibulum tellus.',
        link: 'https://vimeo.com/253278852',
    },
    {
        name: 'Video Six',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam et nulla hendrerit, sollicitudin ex non, vestibulum tellus.',
        link: 'https://vimeo.com/253278852',
    },
    {
        name: 'Video Seven',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam et nulla hendrerit, sollicitudin ex non, vestibulum tellus.',
        link: 'https://vimeo.com/253278852',
    },
    {
        name: 'Video Eight',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam et nulla hendrerit, sollicitudin ex non, vestibulum tellus.',
        link: 'https://vimeo.com/253278852',
    },
];