import React, { useEffect } from "react";
import ReactModal from "react-modal";
import Icon from "./Icon";
import Button from "./Buttons/Button";
import {
	Dialog,
	DialogBackdrop,
	DialogPanel,
	DialogTitle,
} from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";

export default function Modal({
	onClose,
	onBack,
	children,
	heading,
	submitButton,
	show,
	width,
	enableFooter = true,
	...props
}) {
	const handleBack = () => {
		// We can override rhe onClose with a onBack
		// when needed, this is good for multiple modal
		// workflows
		if (onBack) {
			onBack();
		} else {
			onClose();
		}
	};

	return (
		<Dialog
			open={show ? true : false}
			onClose={onClose}
			className="relative z-[9999]"
		>
			<DialogBackdrop
				transition
				className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
			/>

			<div className="fixed inset-0 z-50 w-screen overflow-y-auto">
				<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
					<DialogPanel
						transition
						style={{ width: width + "px" }}
						className="relative transform overflow-hidden pb-4 rounded-lg bg-white  text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8   data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
					>
						<div class="">
							<div className="flex flex-col">
								<DialogTitle
									as="h3"
									className="text-base font-medium text-white px-6 py-4 text-lg leading-6 text-gray-900 bg-[#183055]"
								>
									{heading}
								</DialogTitle>
								<div className="mt-2 text-left px-6 pt-6 pb-6">{children}</div>
								{enableFooter && (
									<div className="flex justify-between border-t border-slate-200 px-6 pt-4">
										<Button onClick={handleBack} left>
											<Icon name={"arrow-left"} /> Back
										</Button>
										{submitButton}
									</div>
								)}
							</div>
						</div>
					</DialogPanel>
				</div>
			</div>
		</Dialog>
	);
}
