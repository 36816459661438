import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "components/Modal";
import Icon from "../../../components/Icon";
import { generateEmbedCode, generateCompatibleEmbedCode } from "../utils";
import Button from "../../../components/Buttons/Button";
import { useQuery, useReactiveVar } from "@apollo/client";
import {
	getProjectEmbedCode,
	setProjectEmbedCode,
} from "../../../graphql/LocalState/projectEmbedCode";
import gql from "graphql-tag";
import ErrorMessage from "../../../components/ErrorMessage";
import styles from "./EmbedCodeModal.module.scss";
import cx from "classnames";
import { HtmlInput, Option } from "../../../components/PropertyEditor";
import { CopyToClipboard } from "react-copy-to-clipboard";

/**
 * Render popup for single project to generate embed code
 * @param show
 * @param close
 * @param project
 * @returns {*}
 * @constructor
 */
const EmbedCodeModal = () => {
	const { projectId, templateId } = useReactiveVar(getProjectEmbedCode);

	const close = () =>
		setProjectEmbedCode({
			projectId: false,
			templateId: false,
		});

	return (
		<Modal
			show={projectId || templateId}
			onClose={close}
			height={750}
			width={1000}
			heading={
				<>
					<Icon name="code" /> Embed Code
				</>
			}
		>
			<div>
				{projectId ? <ProjectEmbedCode projectId={projectId} /> : null}
				{templateId ? <TemplateEmbedCode projectId={templateId} /> : null}
			</div>
		</Modal>
	);
};

export default EmbedCodeModal;

const PROJECT_QUERY = gql`
	query project($projectId: ID!) {
		result: project(id: $projectId) {
			id
			title
			storage_path
			embed_width
			embed_height
			published_path
			image_url
		}
	}
`;

const ProjectEmbedCode = ({ projectId }) => {
	const [tab, setTab] = useState("default");
	const project = useQuery(PROJECT_QUERY, {
		variables: { projectId },
	});

	if (project.loading) return <Icon loading />;

	if (project.error) return <ErrorMessage error={project.error} />;

	return (
		<div>
			<div className={styles.tabWrapper}>
				<a
					onClick={() => setTab("default")}
					className={cx(styles.link, { [styles.selected]: tab === "default" })}
				>
					Default Embed Code
				</a>
				<a
					onClick={() => setTab("compatible")}
					className={cx(styles.link, {
						[styles.selected]: tab === "compatible",
					})}
				>
					Compatibility Embed Code (BETA)
				</a>
			</div>
			<div>
				{tab === "default" ? (
					<DefaltEmbedCode project={project} />
				) : (
					<CompatibleEmbedCode project={project} />
				)}
			</div>
		</div>
	);
	// return <CopyToClipboard value={generateEmbedCode(project.data.result)} />
};

const DefaltEmbedCode = ({ project }) => {
	const [code, setCode] = useState(null);

	useEffect(() => {
		setCode(generateEmbedCode(project.data.result));
	}, []);

	return (
		<div>
			<ExplainerText>
				<p>This code is optimized for page load speed and is the best embed code to use.</p>
				<p>It will only load the video thumbnail so it shows the play icon faster than having to load in the first video, it then loads the video once the user clicks play.</p>
				<p>If you experience issues with this embed code using page builders please use the compatibility embed code.</p>
			</ExplainerText>
			<div className={styles.embedCodeWrapper} style={{height: '220px'}}>
				<Option
					value={code}
					Component={HtmlInput}
					width="965px"
					height="225px"
					onChange={() => {}}
				/>
				<CopyButton code={code} text="Copy Embed Code" />
			</div>
			
		</div>
	);
};

const ExplainerText = ({ children }) => {
	return (
		<div className={styles.explainerText}>
			{children}
		</div>
	);
}

const CompatibleEmbedCode = ({project}) => {
	const [code, setCode] = useState(['','']);

	useEffect(() => {
		setCode(generateCompatibleEmbedCode(project.data.result));
	}, []);
	return (
		<div>
			<ExplainerText>
				<p>This code should be used when the default code is causing issues with your page builder.</p>
				<p>It loads the video in the traditional iframe style which will make the page size bigger but is more compatible with some page builders.</p>
			</ExplainerText>
			<div className={styles.embedCodeWrapper} style={{height: '220px'}}>
				<Option
					value={code[0]}
					Component={HtmlInput}
					width="965px"
					height="220px"
					onChange={() => {}}
				/>
				<CopyButton code={code[0]} text="Copy Embed Code" />
			</div>
			
			<ExplainerText>
				<p>You MUST also add this javascript to your page. Most page builders will have an option to add scripts to the head of the page.</p>
			</ExplainerText>
			<div className={styles.embedCodeWrapper} style={{height: '49px'}}>
				<Option
					value={code[1]}
					Component={HtmlInput}
					width="965px"
					height="40px"
					onChange={() => {}}
				/>
				<CopyButton code={code[1]} text="Copy Header Script" />
			</div>
		</div>
	)
};

const CopyButton = ({ code, text }) => {
	const [copied, setCopied ] = useState(false);
	const copyComplete = () => {
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 1000);
	}
	return (
		<div className={styles.copyButtonWrapper}>
			<CopyToClipboard text={code} onCopy={copyComplete}>
				<Button primary icon="copy">
					{copied ? "Copied!" :text}
				</Button>
			</CopyToClipboard>
		</div>
	);
};

const TEMPLATE_QUERY = gql`
	query template($projectId: ID!) {
		result: template(id: $projectId) {
			id
			title
			storage_path
			embed_width
			embed_height
			published_path
			image_url
		}
	}
`;
const TemplateEmbedCode = ({ projectId }) => {
	const project = useQuery(TEMPLATE_QUERY, {
		variables: { projectId },
	});

	if (project.loading) return <Icon loading />;

	if (project.error) return <ErrorMessage error={project.error} />;

	return <CopyToClipboard value={generateEmbedCode(project.data.result)} />;
};
