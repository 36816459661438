import React from 'react';
import { useReactiveVar } from "@apollo/client";
import styles from "./sharePage.module.scss";
import Button from "../../../components/Buttons/Button";
import RelativeDate from "../../../components/date/RelativeDate";
import {useNavigate} from "react-router-dom";
import {Divider} from "../../../components/Divider";
import { getWhitelabel } from "@/graphql/LocalState/whitelabel";
import getAsset from 'utils/getAsset';

const MoreVideos = ({project}) => {
    const whitelabel = useReactiveVar(getWhitelabel);
    const user = project.user;
    const {logo, name, company_name} = user;

    let logUrl = whitelabel ? whitelabel.logo : logo ? logo :"https://s3.amazonaws.com/interactr-2-source-plppvsc270ey/temp/companyLogos/751a11bb-c648-4b9c-a5c0-a2ba36b666e7.png";
    let brandName = whitelabel ? whitelabel.name : (company_name || name);
    
    const headingStyle = logUrl ? {} : { paddingLeft: 0 };
    let videos = project.otherVideos && project.otherVideos.length ? project.otherVideos : [];

    return (
        <div className={styles.moreVideos}>
           <Divider text="More From" />
            <h3 className={styles.brandText} style={headingStyle}>
                <span className={styles.brandTextContainer}>
                    {logUrl && <img src={logUrl} className={styles.brand} />}
                    { brandName }
                </span>
            </h3>
            <div className="grid" style={{marginLeft: 0, justifyContent: 'center'}}>
                <VideosList videos={videos} logo={logUrl} />
            </div>
         </div>
    );
};
export default MoreVideos;

const VideosList  = ({videos, logo}) => {
    const navigate = useNavigate();
    
    const handleViewClicked = (storagePath) => {
        const hash = storagePath.split("/")[1];        
        navigate( '/share/' + hash );
        window.location.reload();
    }
    return videos.length ? (
        videos.map(video => {

            return (
                <div className={styles.videoCard}>
                    <a className={styles.otherVideo} href={videos.published_path} key={video.id}>
                        <div className={styles.otherVideoThumb}>
                            <div className={styles.overlay}>
                                <Button
                                    secondary
                                    icon="eye"
                                    onClick={() => handleViewClicked(video.storage_path)}
                                >
                                    View
                                </Button>
                            </div>
                            <img src={video.image_url} className={styles.videoImage} />
                        </div>
                        <div className={styles.otherVideoMeta}>
                            <h4>{video.title}</h4>
                            <p>
                                <RelativeDate date={video.created_at} />
                            </p>
                            <div className={styles.otherVideoLogo}>{logo && <img src={logo} />}</div>
                        </div>
                    </a>
                </div>
            );
        })
    ) : (
        <p>No Videos</p>
    )
};