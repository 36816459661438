import '@toast-ui/chart/dist/toastui-chart.min.css';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import {BarChart} from "@toast-ui/react-chart";
import map from "lodash/map";


const SurveysBarChart = ({stats}) => {
  if(isEmpty(stats)) return null;

  const d = {
    categories: ["Responses"],
    series: map(stats, interaction => {
      return {
        name: interaction.element.name,
        data: [parseInt(interaction.clicks)]
      }
    })
  };

  const options = {
    chart: {
      width: 600,
      height: 350,
      title: 'Breakdown by total responses',
    },
    // series: {
    //   stackType: 'normal'
    // },
    // legend: {
    //   visible: true
    // }
    //theme: 'myTheme'
  };

  return(
    <BarChart
      data={d}
      options={options}
    />
  )
}
export default SurveysBarChart;