import React, { useEffect,useCallback } from 'react';
import { getStyles, TEXT_ELEMENT } from 'modules/element/elements';
import PropTypes from "prop-types";
import { SAVE_NODE_PAGE } from 'utils/EventEmitter';
import { cache } from "../../../../graphql/client";
import { TEXT_ELEMENT_FRAGMENT } from "../../../../graphql/TextElement/fragments";
import { useTextElementCommands } from "../../../../graphql/TextElement/hooks";
import EditableTextContainer from "./EditableTextContainer";
import Emitter, { INSERT_DYNAMIC_TEXT } from '@/utils/EventEmitter';

/**
 * Required props for the component
 * @type {{id: *}}
 * @private
 */
const _props = {
  // The id of the element
  element: PropTypes.isRequired
};

/**
 * Render the editable text element
 * @constructor
 */
const TextElement = ({elementId, selected, onSelect, onDelete, animationKey, preview, projectFont}) => {
  const {updateTextElement, saveTextElement} = useTextElementCommands(elementId);

  
  const element = cache.readFragment({
    id: `TextElement:${elementId}`,
    fragment: TEXT_ELEMENT_FRAGMENT
  });
  
  const styles = getStyles(TEXT_ELEMENT, element);
  
  const handleUpdate = useCallback((key, value) => {    
    updateTextElement(key, value);

    const eventName = SAVE_NODE_PAGE;
    const event = new CustomEvent(eventName, {
      detail: {
        __typename: "TextElement",
        id: elementId,
        [key]: value
      },
    });
    window.dispatchEvent(event);
  }, [updateTextElement]);
  
  useEffect(() => {
    const func = ({name, defaultValue, element_id, element_type}) => {
      if(element.id == element_id && element_type == "App\\TextElement") {
        let defaultValues = element?.default_values ? [...element?.default_values] : [];
        for (let i = 0; i < defaultValues.length; i++) {
            if (defaultValues[i].key === name) {
                throw new Error("Duplicate Name!");
            }
        }
  
        let id = !!defaultValues.length && defaultValues[defaultValues.length - 1].id || 0 ;
        defaultValues.push({id: ++ id, key: name, value: defaultValue});
  
        handleUpdate('default_values', [...defaultValues]);
      }
    };
    Emitter.on(INSERT_DYNAMIC_TEXT, func);
  }, [handleUpdate, element]);

  // if(preview) {
    //   return(
      //     <StaticElement
  //       element={element}
  //       animationKey={animationKey}
  //       style={styles}
  //     >
  //       <div dangerouslySetInnerHTML={{__html: element.html}} />
  //     </StaticElement>
  //   )
  // }

  return (
    <EditableTextContainer
      element={element}
      style={styles}
      selected={selected}
      onSelect={onSelect}
      save={saveTextElement}
      update={handleUpdate}
      onDelete={onDelete}
      animationKey={animationKey}
      preview={preview}
      projectFont={projectFont}
    />
  );
};
TextElement.propType = _props;
export default TextElement;