import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useReactiveVar } from "@apollo/client";
import ReactPlayer from 'react-player'
import { Button } from "components/Buttons";
import { Icon, Modal, VideoPlayer } from "components";
import { Option, TextInput } from "components/PropertyEditor";
import { useSaveMedia } from "@/graphql/Media/hooks";
import { errorAlert, success } from "utils";
import {
	getEditMedia,
	setEditMedia,
	SHOW_EDIT_MEDIA_MODAL,
} from "@/graphql/LocalState/editMedia";
import { toast } from "react-toastify";

/**
 * Show popup for updating/deleting the single media item
 * @param media
 * @param closeModal
 * @returns {null|*}
 * @constructor
 */
export const EditMediaModal = () => {
	const [updateMedia, { loading }] = useSaveMedia();
	const { media, activeModal } = useReactiveVar(getEditMedia);
	const [name, setName] = useState("");

	useEffect(() => {
		setName(media?.name);
	}, [media]);



	const handleUpdateMedia = async () => {
		try {
			await updateMedia({
				id: Number(media.id),
				name,
			});
			success("Success");

			handleClose();
		} catch (error) {
			errorAlert({ text: error });
		}
	};

	const handleClose = () => {
		setEditMedia({
			activeModal: "",
			media: null,
		});
	};

	return (
		<Modal
			show={activeModal === SHOW_EDIT_MEDIA_MODAL}
			onClose={handleClose}
			onBack={handleClose}
			width={475}
			heading={
				<>
					<Icon name={"pen-square"} /> Edit Media
				</>
			}
			submitButton={
				<Button
					icon="save"
					primary
					right
					loading={loading}
					onClick={handleUpdateMedia}
				>
					Save
				</Button>
			}
		>
			<div className="grid mx-auto">
				<div className="col12" style={{ marginBottom: "20px" }}>
					<ModalBody media={media} />
				</div>

				<div className="col12">
					<div>
						<Option
							type="text"
							value={name}
							Component={TextInput}
							onChange={(val) => setName(val)}
							onEnter={handleUpdateMedia}
						/>
					</div>
				</div>
			</div>
		</Modal>
	);
};

const ModalBody = ({media}) => {
	if(! media) return null;

	const getVideoURL = () => {
		return media?.url || media?.manifest_url;
	};

	if(media?.is_image){
		return <img src={media?.thumbnail_url} />
	}
	return (
		<ReactPlayer
          className='react-player'
          url={getVideoURL()}
          width='100%'
          height='100%'
		  controls={true}
        />
	);
};

EditMediaModal.propTypes = {
	state: PropTypes.object,
	setState: PropTypes.func,
};
