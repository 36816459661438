import React from 'react';

import { useProjects } from '@/graphql/Project/hooks';
import { useProjectGroups } from '@/graphql/ProjectGroup/hooks';
import { ErrorMessage } from 'components';
import { useProjectGroupRoute } from 'modules/project/routeHooks';
import { NoProjects } from '../ProjectsPage/NoProjects';
import { VideoBubblesList } from './VideoBubblesList';
import { ProjectsPageBody } from '../ProjectsPage/ProjectsPageBody';
import { useEffect } from 'react';
import { MIGRATION_UPDATE } from '../../../../utils/EventEmitter';

/**
 * Page for looking projects list
 * @returns {*}
 * @constructor
 */
export const VideoBubblesPage = () => {
	const [folderId, , { page, q, orderBy, sortOrder }] = useProjectGroupRoute();
	const [
		projects,
		,
		{ loading: projectsLoading, error, refetch: refetchProjects },
	] = useProjects({
		search: q,
		page: parseInt(page),
		project_group_id: folderId,
		orderBy,
		sortOrder,
	});

	// Loading the folders into cache so we can just query cache on the project components
	// const { loading: foldersLoading, error: foldersError } = useQuery(GET_PROJECT_GROUPS);
	const [
		projectGroups,
		,
		{ loading: foldersLoading, refetch: refetchProjectGroups },
	] = useProjectGroups();

	// useEffect(() => {
	// 	(async function () {
	// 		await refetchProjects();
	// 		await refetchProjectGroups();
	// 	})();
	// }, [refetchProjects, refetchProjectGroups]);
	
	const refetch = () => {
		refetchProjects();
		refetchProjectGroups();
	};

	useEffect(() => {
		window.addEventListener(MIGRATION_UPDATE, refetch);
		return () => {
			window.removeEventListener(MIGRATION_UPDATE, refetch);
		}
	}, [refetchProjects, refetchProjectGroups]);

	if (error)
		return (
			<ProjectsPageBody>
				<ErrorMessage error={error} />
			</ProjectsPageBody>
		);

	const loading = projectsLoading || foldersLoading;
	
	return (
		<ProjectsPageBody
			paginatorInfo={projects?.paginatorInfo || {}}
			loading={loading}
			foldersLoading={foldersLoading}
			projectGroups={projectGroups}
			projectsLoading={loading}
			context="video-bubbles"
		>
			{
				!loading && projects?.data.length == 0 
				? 
					<NoProjects /> 
				: 
				<>
					{
						folderId < 0? 
							<div 
								style={{
									marginBottom: '15px',
									marginLeft: '5px',
									width: 'fit-content',
									backgroundColor: 'rgb(254 252 232)',
									borderRadius: '0.375rem',
									padding: '0.75rem'
								}}>
								<div style={{display: 'flex'}}>
									<div style={{flexShrink: 0}}>
										<svg style={{color: 'rgb(250 204 21)', width: '1.25rem', height: '1.25rem'}} viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
											<path fill-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
										</svg>
									</div>
									<div style={{marginLeft: '0.75rem'}}>
										<div style={{color: 'rgb(133 77 14)', fontWeight: 600, fontSize: '0.875rem',lineHeight: '1.25rem'}}>Important</div>
										<div style={{color: 'rgb(161 98 7)', fontSize: '0.875rem', marginTop: '0.5rem'}}>
											<p>
												Once you have migrated your projects to Interactr 2.0 you will need to get the new 2.0 embed code from the project and replace the old embed code wherever the project has been published.
											</p>
										</div>
									</div>
								</div>
							</div> 
						: null
					}
					<VideoBubblesList
						projects={projects?.data}
						loading={loading}
						refetchProjects={refetch}
					/>
				</>
			}
		</ProjectsPageBody>
	);
};
