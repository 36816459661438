import { LargeTextInput, Option } from "components/PropertyEditor";
import { useState } from "react";
import Button from "components/Buttons/Button";
import { useProjectCommands } from "@/graphql/Project/hooks";
import { useNavigate } from 'react-router-dom';

export const ProjectSetupForm = ({}) => {
	const { createBlankProject, createProjectFromTemplate } =
		useProjectCommands();
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const createProject = async () => {
		try {
			setLoading(true);
			const {
				data: { createBlankProject: project },
			} = await createBlankProject({
				variables: {
					input: {
						title,
						description,
						embed_width: 230,
						is_bubble: 1,
						project_group_id: null,
					},
				},
			});
			console.log(project);
			navigate('/projects/add/videobubble/step-2?id='+ project.id);
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};
	return (
		<div class="max-w-2xl mx-auto">
			<h2 class="text-center  text-3xl font-semibold mb-2">
				Lets give your new project a name...
			</h2>
			<div className="form-control text-center">
				<input
					id="title"
					type="text"
					name="title"
					value={title}
					className=""
					onChange={(e) => setTitle(e.target.value)}
				/>
			</div>
			<h2 class="text-center text-2xl mb-2">
				And add a description if you like
			</h2>
			<div className="form-control text-center">
				<Option
					name="description"
					value={description}
					Component={LargeTextInput}
					onChange={(val) => setDescription(val)}
				/>
			</div>
			<div className="flex justify-end">
				{loading ? (
					<i class="fa-solid fa-spinner-third fa-spin mt-4"></i>
				) : (
					<Button primary large onClick={createProject}>
						Next Step<i class="fa-solid fa-angle-right ml-2"></i>
					</Button>
				)}
			</div>
		</div>
	);
};
