import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Logo from "./Logo";
import {
	Dialog,
	DialogPanel,
	Transition,
	TransitionChild,
} from "@headlessui/react";
import {
	Bars3Icon,
	HomeIcon,
	UsersIcon,
	XMarkIcon,
	CameraIcon,
	LifebuoyIcon,
	UserCircleIcon,
	ArrowRightStartOnRectangleIcon,
	ChatBubbleOvalLeftIcon,
	ShareIcon,
} from "@heroicons/react/24/outline";
import { useReactiveVar } from "@apollo/client";
import { getWhitelabel } from "../../graphql/LocalState/whitelabel";
import SidebarStatic from "./SidebarStatic";
import { useAuthUser } from "../../graphql/User/hooks";
import { getAcl, setAcl } from "../../graphql/LocalState/acl";

const projectTypes = [
	//  { id: 2, name: 'Video Bubbles', href: '/video-bubbles', icon: ChatBubbleOvalLeftIcon, initial: 'VB', current: false },
	{
		id: 1,
		name: "Interactive Videos",
		href: "/projects",
		icon: ShareIcon,
		initial: "IV",
		current: false,
	},
	//   { id: 3, name: 'Video Hosting', href: '/projects', icon: ShareIcon, initial: 'IV', current: false },
];

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function Sidebar({ children }) {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const whitelabel = useReactiveVar(getWhitelabel);
	const user = useAuthUser();
	const acl = useReactiveVar(getAcl);

	const navigation = [
		{
			name: "Media",
			href: "/media?filterBy=all&page=1&q=&orderBy=created_at&sortOrder=DESC&project_id=0&activeTab=all",
			icon: CameraIcon,
			current: false,
			hidden: user.parent_user_id > 0,
		},
		{
			name: "Agency",
			href: "/agency",
			icon: UsersIcon,
			current: false,
			hidden: !acl.hasAgency,
		},
		{
			name: "Training",
			href: "/training",
			icon: LifebuoyIcon,
			current: false,
			hidden: user.parent_user_id > 0,
		},
		{ name: "Profile", href: "/account", icon: UserCircleIcon, current: false },
		//{ name: 'Logout', href: '#', icon: ArrowRightStartOnRectangleIcon, current: false },
	];

	return (
		<>
			{/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
			<div class="w-full">
				<SidebarStatic
					whitelabel={whitelabel}
					navigation={navigation}
					projectTypes={projectTypes}
					user={user}
				/>

				<div className="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
					<button
						type="button"
						className="-m-2.5 p-2.5 text-gray-400 lg:hidden"
						onClick={() => setSidebarOpen(true)}
					>
						<span className="sr-only">Open sidebar</span>
						<Bars3Icon className="h-6 w-6" aria-hidden="true" />
					</button>
					<div className="flex-1 text-sm font-semibold leading-6 text-white">
						Dashboard
					</div>
					<a href="#">
						<span className="sr-only">Your profile</span>
						<img
							className="h-8 w-8 rounded-full bg-gray-800"
							src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
							alt=""
						/>
					</a>
				</div>

				<div className="ml-[225px]">{children}</div>
			</div>
		</>
	);
}
