import '@toast-ui/chart/dist/toastui-chart.min.css';
import React from 'react';
import {LineChart} from '@toast-ui/react-chart'

export default class DropOffChartProject extends React.Component {
    constructor(props) {
        super(props);
    }

    render(){
        const {data} = this.props;

        if(! data) return null;

        var d = {
            categories: data.project.map(d=>d.timestamp),
            series: [
                {
                    name: this.props.project.title,
                    data: data.project.map(d=> parseInt(d.count))
                }
            ]
        };

        d.categories.unshift('00:00');
        d.series[0].data.unshift( parseInt(data.plays) );

        var options = {
            chart: {
                width: 1160,
                height: 540,
                title: 'Project Drop Off'
            },
            yAxis: {
                title: 'Viewers',
            },
            xAxis: {
                title: 'Time',
                pointOnColumn: true,
                tickInterval: 'auto'
            },
            series: {
                showDot: true,
                zoomable: true
            },
            tooltip: {
                suffix: 'Viewers'
            },
        };

        return(
            <LineChart
                data={d}
                options={options}
            />
        );
    }
}