import { useState } from 'react';
import Select from 'react-select';
import useAweberAuth from '@/hooks/useAweberAuth';
import useAweberAccounts from '@/hooks/useAweberAccounts';
import { useAuthUser, useSaveUser } from '@/graphql/User/hooks';
import Button from '@/components/Buttons/Button';

const AweberIntegration = () => {
	const user = useAuthUser();

	if (!user.integration_aweber?.refresh_token) {
		return <AweberAuth />;
	}

	return <AweberAccount />;
};

const AweberAuth = () => {
	const { loading, aweberAuthUrl } = useAweberAuth();

	return (
		<Button primary icon="save" loading={loading} style={{ width: 'fit-content' }}>
			<a href={aweberAuthUrl} style={{ color: 'inherit' }}>
				Authorize
			</a>
		</Button>
	);
};

const AweberAccount = () => {
	const { loading, accounts, selectedAccount, select, disconnect } = useAweberAccounts();

	return (
		<>
			<Select
				value={selectedAccount}
				options={accounts}
				onChange={(account) => select(account)}
				isLoading={loading}
				isClearable={true}
				formatOptionLabel={(data) => data.company}
				styles={{
					container: (baseStyles) => ({
						...baseStyles,
						display: 'block',
						width: '100%',
						marginBottom: '15px',
					}),
					control: (baseStyles) => ({
						...baseStyles,
						borderRadius: '10px',
					}),
				}}
			/>

			<Button primary icon="save" style={{ width: 'fit-content' }} loading={loading} onClick={() => disconnect()}>
				Disconnect
			</Button>
		</>
	);
};

export default AweberIntegration;
