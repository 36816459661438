import React, { useState, useEffect } from "react";
import PageBody from "components/PageBody";
import { useAuthUser } from "../../../graphql/User/hooks";
import Icon from "../../../components/Icon";
import ErrorMessage from "../../../components/ErrorMessage";
import { useAddProjectRoute } from "modules/project/routeHooks";
import ProTemplates from "./ProTemplates";
import { setBreadcrumbs } from "../../../graphql/LocalState/breadcrumb";
import { dashboardPath } from "../../dashboard/routes";
import { addProjectPath, projectsPath } from "../routes";
import SubNav from "../../../components/SubNav";
import DFYTemplatesPage from "./DFYTemplatesPage";
import Button from "../../../components/Buttons/Button";
import { setAddProject } from "../../../graphql/LocalState/addProject";
import { Preview } from "./AddVideoBubbleProject/Preview";
import { ProjectSetupForm } from "./AddVideoBubbleProject/ProjectSetupForm";
import { Steps } from "./AddVideoBubbleProject/Steps";
import { AddVideoForm } from "./AddVideoBubbleProject/AddVideoForm";
import { CustomizeWidgetForm } from "./AddVideoBubbleProject/CustomizeWidgetForm";



/**
 * Page wrapper for adding a new project. Allows the user to select from a template
 * or create a  blank project.
 * @returns {*}
 * @constructor
 */
const AddProjectPage = () => {
	const [{ activeTab }, setActiveTab] = useAddProjectRoute();

	setBreadcrumbs([
		{ text: "Dashboard", link: dashboardPath() },
		{ text: "Projects", link: projectsPath() },
		{ text: "Create New Project" },
	]);

	const setActiveSelectedTab = (activeTab) => setActiveTab(activeTab);

	return (
		<PageBody heading={<PageHeading />} subnav={<Steps  activeTab={activeTab}/>}>
			<CurrentStep activeTab={activeTab}  />
		</PageBody>
	);
};
export default AddProjectPage;

const PageHeading = () => {
	return (
		<div style={{ marginBottom: "5px" }}>
			<span style={{ lineHeight: "38px" }}>Create a new VideoBubble</span>
		</div>
	);
};


/**
 * Switch between the different content body tabs
 * @param activeTab
 * @param user
 * @param onSelect
 * @param setActiveTab
 * @returns {null|*}
 * @constructor
 */
const CurrentStep = ({ activeTab, state, setState, setActiveTab, setStep }) => {
	console.log(activeTab)
	switch (activeTab) {
		case "step-1":
			return <ProjectSetupForm />;
		case "step-2":
			return <AddVideoForm />;
		case "step-3" :
			return <CustomizeWidgetForm />
		default:
			return null;
	}
};
