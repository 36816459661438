import { useCallback } from "react";
import { phpApi } from "utils/apis";
import { useState } from "react";

import { useRef, useEffect } from "react";
import { Button } from "components/Buttons";
import { GET_MEDIAS } from "@/graphql/Media/queries";
import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useMediaLibraryRoute } from "modules/media/routeHooks";
import { useMedias } from "@/graphql/Media/hooks";
import { useProject } from "@/graphql/Project/hooks";
import { useTusUploader } from "../useTusUploader";
import { useBase64Uploader } from "../useBase64Uploader";

export const MEDIA_LIBRARY_QUERY_PARAMS = {
	first: 30,
	orderBy: "created_at",
	sortOrder: "DESC",
};
const UploadMediaItem = ({ file }) => {
	const [project] = useProject();
	const [isImage] = useState(file.type.startsWith("image/"));
	const [name, setName] = useState(file.name.split(".")[0]);

	const [{ isOpen, activeTab, page, q, filterBy }] = useMediaLibraryRoute();
	const [m, n, { refetch }] = useMedias({
		...MEDIA_LIBRARY_QUERY_PARAMS,
		q,
		filterBy,
		page: parseInt(page),
	});

	return (
		<div style={{ display: "flex", marginBottom: "40px" }}>
			<div
				style={{
					borderRadius: "10px",
					overflow: "hidden",
					border: "#f3f6fd 1px solid",
				}}
			>
				{isImage ? <ImagePreview file={file} /> : <VideoPreview file={file} />}
			</div>
			<div style={{ marginLeft: "20px" }}>
				<div
					className="form-control"
					style={{ marginBottom: "5px", width: "300px" }}
				>
					<label htmlFor="title">Enter a name for your video</label>
					<input
						id="title"
						type="text"
						name="title"
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
				</div>
				{isImage ? (
					<ImageUploadButton
						file={file}
						name={name}
						project={project}
						refetch={refetch}
					/>
				) : (
					<VideoUploadButton
						file={file}
						name={name}
						project={project}
						refetch={refetch}
					/>
				)}
			</div>
		</div>
	);
};

const ImageUploadButton = ({ file, name, project, refetch }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [progress, setProgress] = useState(0);
	const [success, setSuccess] = useState(false);

	const { start } = useBase64Uploader();
	const handleUpload = async () => {
		try {
			setLoading(true);
			const req = await start({ file });
			const res = await phpApi("upload/create-new-image", {
				method: 'post',
				body:{
					url: req.url,
					name,
					project_id: project.id,
				}
			});
			refetch();
			setSuccess(true);
		}catch(e){
			console.error(e);
			setError("Error uploading file");
		}finally {
			setLoading(false);
		}
	};
	return (
		<UploadButton
			handleUpload={handleUpload}
			loading={loading}
			error={error}
			progress={progress}
			success={success}
		/>
	);
};

const VideoUploadButton = ({ file, name, project, refetch }) => {
	const { start, loading, error, progress } = useTusUploader();
	const handleUpload = async () => {
		start({
			file,
			type: file.type,
			name: name ? name : file.name,
			projectId: project.id,
			onComplete: () => {
				refetch();
			},
		});
	};
	return (
		<UploadButton
			handleUpload={handleUpload}
			loading={loading}
			error={error}
			progress={progress}
		/>
	);
};

const UploadButton = ({ handleUpload, loading, progress, error , success}) => {
	const background = progress > 99 ? "#41c186" : "rgb(24, 48, 85)";
	if (progress > 0) {
		return (
			<div style={{ marginBottom: "5px", width: "300px" }}>
				<div
					style={{
						width: `${progress}%`,
						borderRadius: "5px",
						marginTop: "10px",
						color: "white",
						padding: "5px 15px",
						backgroundColor: background,
					}}
				>
					{progress >= 100
						? "Upload Complete"
						: `${parseFloat(progress).toFixed()}%`}
				</div>
			</div>
		);
	}
	if (error) {
		return (
			<p>Error Uploading File</p>
		)
	}
	if (success) {
		return (
			<p>Complete!</p>
		)
	}
	return (
		<Button
			primary
			icon="cloud-upload"
			onClick={handleUpload}
			loading={loading}
		>
			Upload Media
		</Button>
	);
};

const VideoPreview = ({ file }) => {
	const myRef = useRef(null);

	useEffect(() => {
		var video = myRef.current;
		video.file = file;
		var reader = new FileReader();
		reader.onload = (function (aVideo) {
			return function (e) {
				aVideo.src = e.target.result;
				console.log(e.target);
			};
		})(video);
		reader.readAsDataURL(file);
	}, [myRef]);

	return (
		<div>
			<video ref={myRef} style={{ maxWidth: "180px", maxHeight: "100px" }} />
		</div>
	);
};

const ImagePreview = ({ file }) => {
	return (
		<div>
			<img
				src={URL.createObjectURL(file)}
				style={{ maxWidth: "180px", maxHeight: "100px" }}
			/>
		</div>
	);
};

export default UploadMediaItem;
