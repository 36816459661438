import '@toast-ui/chart/dist/toastui-chart.min.css';
import React from 'react';
import {AreaChart} from '@toast-ui/react-chart'
import analytics from 'utils/analytics';
import map from 'lodash/map';
import union from 'lodash/union';
import find from "lodash/find";
import take from "lodash/take";
import isEmpty from "lodash/isEmpty";
import reduce from 'lodash/reduce'

export default class ProjectsViewsByAll extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            groupBy:'day'
        }
    }

    noData(){
        return (
            <div className="grid">
                <div className="col12">
                    <div style={{border: '1px solid #ccc', height: '400px', width: '100%', textAlign: 'center', borderRadius: '20px'}}>
                        <h3 style={{lineHeight: '400px', margin: '0'}}>Not Enough Data For Project Views Chart</h3>
                    </div>
                </div>
            </div>
        )
    }

    /**
     * Because a project could have a value for impressions but
     * not for plays we need to do a check for that here.
     *
     * It could also have plays but no impressions if a legacy project so
     * we need to do some cross referencing
     *
     * @param data
     */
    formatChartData(data){
        const impressionProjectIds =  Object.keys(data.impressions);
        const playProjectIds = Object.keys(data.plays);

        // Get a unique array of project ids
        const projectIds = take(
            union(
                impressionProjectIds, playProjectIds
            ),
            10
        );

        // now loop through the array of project id's and either get
        // the matching value or set to 0
        const plays = map(projectIds, projectId=>{
            return (playProjectIds && playProjectIds.includes(projectId)) ? data.plays[projectId] : 0;
        });

        const impressions = map(projectIds, projectId=>{
            return (impressionProjectIds && impressionProjectIds.includes(projectId)) ? data.impressions[projectId] : 0;
        });

        const projectNames = map(projectIds, id=>{
            const project = find(this.props.projects, {"id": id });
            let title =  (project) ? project.title : '-';
            if(project.legacy) title = "* "+title;
            return title;
        });

        return {plays, impressions, projectNames};
    }

    render(){
        const {data, labels, title} = this.props;

        if( isEmpty(data) ) {
            return this.noData();
        }
        analytics.registerTheme();

        let d = {
            // categories: reduce(projects, (result, project)=>{
            //     return result.concat(project.title)
            // }, []),

            categories: labels && labels.length > 0 ? analytics.getCategoriesFromQuery(data[labels[0]?.id], this.state.groupBy) : [],

            series: reduce(labels, (result, label) => {
                return result.concat({
                    name: label.title,
                    data: analytics.getSeriesFromQuery(data[label.id])
                })
            }, [])
        };

        let options = {
            chart: {
                width: 1280,
                height: 400,
                title: title
            },
            yAxis: {
                title: 'Views',
                min: 0,
                pointOnColumn: true
            },
            xAxis: {
                title: 'Date',
                tickInterval: 'auto'
            },
            tooltip: {
                // grouped: true,
            },
            theme: 'myTheme'
        };

        return(
          <AreaChart
            data={d}
            options={options}
          />
        );
    }
}