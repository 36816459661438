import Logo from "./Logo";
import AccountAvatar from "../AccountAvatar";
import {
	HomeIcon,
	CameraIcon,
	ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/outline";
import { NavLink, Link } from "react-router-dom";
import { logout } from "@/modules/auth/utils";
import { ArrowRightStartOnRectangleIcon } from "@heroicons/react/24/outline";
import filter from "lodash/filter";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const sidebarStatic = ({ whitelabel, navigation, projectTypes, user }) => {
	const subNavigation = filter(navigation, (item) => !item.hidden);
	return (
		<>
			{/* Static sidebar for desktop */}
			<div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-[225px] lg:flex-col ">
				{/* Sidebar component, swap this element with another sidebar if you like */}
				<div className="flex grow flex-col overflow-y-auto bg-[#183055] px-6 text-[#E2E5E9]">
					<div className="mt-[30px]">
						<AccountAvatar user={user} />
					</div>
					<nav className="flex flex-1 flex-col">
						<ul role="list" className="flex flex-1 flex-col gap-y-7">
							<li>
								<ul role="list" className="-mx-2 space-y-1">
									{user.parent_user_id === 0 && (
										<li>
											<NavLink
												to={"/dashboard"}
												className=" text-[#E2E5E9]  hover:text-white hover:bg-[#6C7A90] group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
											>
												<HomeIcon
													className="h-6 w-6 shrink-0"
													aria-hidden="true"
												/>
												Dashboard
											</NavLink>
										</li>
									)}
									{/* <li>
										<NavLink
											to={'/dashboard'}
											className=" text-[#E2E5E9]  hover:text-white hover:bg-[#6C7A90] group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
										>
											<ChatBubbleLeftRightIcon
												className="h-6 w-6 shrink-0"
												aria-hidden="true"
											/>
											Conversations
										</NavLink>
									</li> */}
								</ul>
							</li>
							<li>
								<div className="text-sm font-semibold leading-6 text-[#E2E5E9] tracking-wide">
									Your Projects
								</div>
								<ul role="list" className="-mx-2 mt-2 space-y-1">
									{projectTypes.map((team) => (
										<li key={team.name}>
											<NavLink
												to={team.href}
												className={classNames(
													team.current
														? "bg-gray-800 text-white"
														: "text-[#E2E5E9] hover:text-white  hover:bg-[#6C7A90]",
													"group flex gap-x-2 rounded-md p-2 text-sm tracking-wider leading-6 font-semibold items-center"
												)}
											>
												<team.icon
													className="h-5 w-5 shrink-0"
													aria-hidden="true"
												/>
												<span className="truncate">{team.name}</span>
											</NavLink>
										</li>
									))}
								</ul>
							</li>
							<li>
								<ul role="list" className="-mx-2 space-y-1">
									{subNavigation.map((item) => (
										<li key={item.name}>
											<NavLink
												to={item.href}
												className={classNames(
													item.current
														? "bg-gray-800 text-white"
														: "text-[#E2E5E9]  hover:text-white  hover:bg-[#6C7A90]",
													"group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
												)}
											>
												<item.icon
													className="h-6 w-6 shrink-0"
													aria-hidden="true"
												/>
												{item.name}
											</NavLink>
										</li>
									))}
									<li key="logout">
										<a
											onClick={logout}
											className={classNames(
												false
													? "bg-gray-800 text-white"
													: "text-[#E2E5E9]  hover:text-white  hover:bg-[#6C7A90]",
												"group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
											)}
										>
											<ArrowRightStartOnRectangleIcon
												className="h-6 w-6 shrink-0"
												aria-hidden="true"
											/>
											Logout
										</a>
									</li>
								</ul>
							</li>

							<li className="mt-auto pb-[15px]">
								<Logo whitelabel={whitelabel} />
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</>
	);
};

export default sidebarStatic;
