import React from "react";
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { LinkButton } from "components/Buttons";
import { addProjectPath } from "../routes";

export const AddProjectButton = ({ children }) => {
	const location = useLocation();
	const [path, setPath ] = React.useState("");

	React.useEffect(() => {
		setPath(addProjectPath());
		// if(location.pathname === "/projects") {
			
		// }else {
		// 	setPath("/projects/add/videobubble/step-1")
		// }
	  }, [location]);
	
  return (
    <LinkButton
      to={path}
      primary
      icon={'plus'}
      right
    >
      {children || "Create New Project"}
    </LinkButton>
  );
}

AddProjectButton.propTypes = {
  children: PropTypes.element
}