import cx from "classnames";
import styles from "./Timeline.module.scss";
import PlayHead from "./PlayHead";
import times from "lodash/times";
import Time from "components/Time";

const TimelineAxis = ({getXForPlayed, jumpToTime, playedSeconds, handleScrub, duration}) => {

	return (
		<div className={styles.TimelineAxis}>
				<div
					className={cx(styles.elementName, styles.startEndTimeHeading)}
					style={{ textAlign: "left" }}
				>
					<small>
						<strong>&nbsp;</strong>
					</small>
				</div>
				<div
					className={cx(styles.axisWrapper, styles.bars)}
					id="axis-wrapper"
					onClick={jumpToTime}
				>
					<PlayHead x={getXForPlayed(playedSeconds)} onChange={handleScrub} />{" "}
					<Axis duration={duration}  />
				</div>
				<div
					className={cx(styles.startEndTime, styles.startEndTimeHeading)}
					style={{ textAlign: "left" }}
				>
					<small>
						<strong style={{ paddingLeft: "11px" }}>TIME IN</strong>
						<strong style={{ paddingLeft: "23px" }}>TIME OUT</strong>
					</small>
				</div>
			</div>
	)
};

const Axis = ({ duration }) => {
	let markerCount = 10;

	return (
		<div className={styles.axis}>
			{times(markerCount, (i) => (
				<div className={styles.marker} key={i}>
					<Time s={(i * duration) / markerCount} />
				</div>
			))}
		</div>
	);
};

export default TimelineAxis;