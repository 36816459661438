import React from "react";
import PageBody from "components/PageBody";
import { getAcl } from "./graphql/LocalState/acl";
import { useReactiveVar } from "@apollo/client";
import { setBreadcrumbs } from "./graphql/LocalState/breadcrumb";
import { setPageHeader } from "./graphql/LocalState/pageHeading";
import { useAuthUser } from "./graphql/User/hooks";
import { CheckIcon } from "@heroicons/react/20/solid";
import { useState } from 'react'
import { Radio, RadioGroup } from '@headlessui/react'

const includedFeatures = [
	"Advanced Interactive Layer Builder",
	"Done For You Interaction Layer Templates",
	"Built In Surveys & Quiz Builder",
	"Premium Stock Video Footage Library",
];

const includedFeaturesAgency = [
	"Add Your Own Branding To The App",
	"Use Your Own Domain",
	"Add Sub Users Directly To Your Dashboard",
	"Market Your Services With A Deluxe Explainer Video",
];

const includedFeaturesMasterclass = [
	"6 Module program on-demand",
	"Expert instruction from 6-figure agency creator Phil Naylor",
	"Business foundations",
	"Making videos clients love",
];

export default function Example() {
	const authUser = useAuthUser();

	setBreadcrumbs([{ text: "Upgrade" }]);

	setPageHeader("Upgrade your account");
	return (
		<PageBody>
			<div className="bg-white ">
				<div className="mx-auto max-w-7xl px-6 lg:px-8">
					<h2 className="text-2xl font-medium mb-4">
						Available Interactr Upgrades
					</h2>
					<InteractrPro authUser={authUser} />
					<InteractrAgency authUser={authUser} />
					<InteractrMasterclass />
					<UpgradeStorage />
				</div>
			</div>
		</PageBody>
	);
}

const InteractrPro = ({ authUser }) => {
	return (
		<div className="mx-auto max-w-2xl rounded-3xl ring-1 ring-gray-200 lg:mx-0 lg:flex lg:max-w-none">
			<div className="p-8 sm:p-10 lg:flex-auto">
				<h3 className="text-2xl font-bold tracking-tight text-gray-900">
					Interactr Pro
				</h3>
				<p className="mt-6 text-base leading-7 text-gray-600">
					Maximize Your Competitive Advantage With The Power Of Interactr Pro
					including Amplified Video View Time, Expanded Video Storage and New
					Interactr Features.
				</p>
				<div className="mt-10 flex items-center gap-x-4">
					<h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
						What’s included
					</h4>
					<div className="h-px flex-auto bg-gray-100" />
				</div>
				<ul
					role="list"
					className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
				>
					{includedFeatures.map((feature) => (
						<li key={feature} className="flex gap-x-3">
							<CheckIcon
								aria-hidden="true"
								className="h-6 w-5 flex-none text-indigo-600"
							/>
							{feature}
						</li>
					))}
				</ul>
			</div>
			<div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
				<div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
					<div className="mx-auto max-w-xs px-8">
						<p className="text-base font-semibold text-gray-600">
							Pay once, own it forever
						</p>
						<p className="mt-6 flex items-baseline justify-center gap-x-2">
							<span className="text-5xl font-bold tracking-tight text-gray-900">
								$97
							</span>
							<span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
								USD
							</span>
						</p>

						{authUser.is_pro ? (
							<p className="mt-5">Already Purchased</p>
						) : (
							<a
								href="https://interactr.io/upgrade/pro"
								target="_blank"
								className="mt-5 block w-full rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							>
								Get access
							</a>
						)}
						{/* <p className="mt-6 text-xs leading-5 text-gray-600">
										Invoices and receipts available for easy company
										reimbursement
									</p> */}
					</div>
				</div>
			</div>
		</div>
	);
};

const InteractrAgency = ({ authUser }) => {
	return (
		<div className="mx-auto mt-8  max-w-2xl rounded-3xl ring-1 ring-gray-200 lg:mx-0 lg:flex lg:max-w-none">
			<div className="p-8 sm:p-10 lg:flex-auto">
				<h3 className="text-2xl font-bold tracking-tight text-gray-900">
					Interactr Agency
				</h3>
				<p className="mt-6 text-base leading-7 text-gray-600">
					Interactr Agency Is The Quickest & Easiest Way To Profit With
					Interactr. Get access to Exclusive Interactr Agency Features To Help
					You Grow, Scale & Profit
				</p>
				<div className="mt-10 flex items-center gap-x-4">
					<h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
						What’s included
					</h4>
					<div className="h-px flex-auto bg-gray-100" />
				</div>
				<ul
					role="list"
					className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
				>
					{includedFeaturesAgency.map((feature) => (
						<li key={feature} className="flex gap-x-3">
							<CheckIcon
								aria-hidden="true"
								className="h-6 w-5 flex-none text-indigo-600"
							/>
							{feature}
						</li>
					))}
				</ul>
			</div>
			<div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
				<div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
					<div className="mx-auto max-w-xs px-8">
						<p className="text-base font-semibold text-gray-600">
							Pay once, own it forever
						</p>
						<p className="mt-6 flex items-baseline justify-center gap-x-2">
							<span className="text-5xl font-bold tracking-tight text-gray-900">
								$347
							</span>
							<span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
								USD
							</span>
						</p>

						{authUser.is_agency ? (
							<p className="mt-5">Already Purchased</p>
						) : (
							<a
								href="https://interactr.io/upgrade/agency"
								target="_blank"
								className="mt-5 block w-full rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							>
								Get access
							</a>
						)}
						{/* <p className="mt-6 text-xs leading-5 text-gray-600">
										Invoices and receipts available for easy company
										reimbursement
									</p> */}
					</div>
				</div>
			</div>
		</div>
	);
};

const InteractrMasterclass = () => {
	return (
		<div className="mx-auto mt-8  max-w-2xl rounded-3xl ring-1 ring-gray-200 lg:mx-0 lg:flex lg:max-w-none">
			<div className="p-8 sm:p-10 lg:flex-auto">
				<h3 className="text-2xl font-bold tracking-tight text-gray-900">
					Interactr Masterclass
				</h3>
				<p className="mt-6 text-base leading-7 text-gray-600">
					Get Started With Your Own Successful Video Agency With Masterclass
				</p>
				<div className="mt-10 flex items-center gap-x-4">
					<h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
						What’s included
					</h4>
					<div className="h-px flex-auto bg-gray-100" />
				</div>
				<ul
					role="list"
					className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
				>
					{includedFeaturesMasterclass.map((feature) => (
						<li key={feature} className="flex gap-x-3">
							<CheckIcon
								aria-hidden="true"
								className="h-6 w-5 flex-none text-indigo-600"
							/>
							{feature}
						</li>
					))}
				</ul>
			</div>
			<div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
				<div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
					<div className="mx-auto max-w-xs px-8">
						<p className="text-base font-semibold text-gray-600">
							Pay once, own it forever
						</p>
						<p className="mt-6 flex items-baseline justify-center gap-x-2">
							<span className="text-5xl font-bold tracking-tight text-gray-900">
								$97
							</span>
							<span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
								USD
							</span>
						</p>

						<a
							href="https://interactr.io/upgrade/masterclass"
							target="_blank"
							className="mt-5 block w-full rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						>
							Get access
						</a>
						{/* <p className="mt-6 text-xs leading-5 text-gray-600">
										Invoices and receipts available for easy company
										reimbursement
									</p> */}
					</div>
				</div>
			</div>
		</div>
	);
};

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'annually', label: 'Annually', priceSuffix: '/year' },
]
const tiers = [
  {
    name: '1,000 Overage Mins',
    id: 'tier-freelancer',
    href: 'https://app.paykickstart.com/checkout/pAYXBlDw6VGPdkggdKgkgqx0Z9Q1y5mR',
    price:'37',
    mostPopular: false,
  },
  {
    name: '5,000 Overage Mins',
    id: 'tier-startup',
    href: 'https://app.paykickstart.com/checkout/45nv3ME1weJgxz3JOdEjdL07plRBGqDo',
    price:'67',
    mostPopular: true,
  },
  {
    name: '20,000 Overage Mins',
    id: 'tier-enterprise',
    href: 'https://app.paykickstart.com/checkout/qVBwA5YD1K90djOX6DazGEovQr4OJnyp',
    price:'97',
    mostPopular: false,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const UpgradeStorage = () =>  {
  
  return (
    <div className="bg-white mt-16 mb-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <p className="mx-auto max-w-2xl text-center text-2xl leading-8 text-gray-600">
          Purchase Additional Streaming Mins
        </p>
     
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-200',
                'rounded-3xl p-8 xl:p-10',
              )}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h3
                  id={tier.id}
                  className={classNames(
                    tier.mostPopular ? 'text-indigo-600' : 'text-gray-900',
                    'text-lg font-semibold leading-8',
                  )}
                >
                  {tier.name}
                </h3>
                {tier.mostPopular ? (
                  <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
                    Most popular
                  </p>
                ) : null}
              </div>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-gray-900">${tier.price}</span>
                <span className="text-sm font-semibold leading-6 text-gray-600">One Time</span>
              </p>
              <a
                href={tier.href}
                aria-describedby={tier.id}
				target="_blank"
                className={classNames(
                  tier.mostPopular
                    ? 'bg-blue-600 text-white shadow-sm hover:bg-blue-200'
                    : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                  'mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
                )}
              >
                Buy {tier.name}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}


const UpgradePage = () => {
	const authUser = useAuthUser();

	setBreadcrumbs([{ text: "Upgrade" }]);

	setPageHeader("Upgrade your account");

	return (
		<PageBody>
			<div className="grid" style={{ marginLeft: "30px" }}>
				<div className="w-100">
					<h3>Available Upgrades</h3>
				</div>
				<div className="w-100">
					<div>
						<h4 style={{ marginTop: "20px", marginBottom: "0px" }}>
							Interactr Pro
						</h4>
						{authUser.is_pro ? (
							<p>Already Purchased</p>
						) : (
							<a
								style={{ color: "blue" }}
								target="_blank"
								href="https://interactr.io/upgrade/pro"
								rel="noreferrer"
							>
								Upgrade Here
							</a>
						)}

						{/* <h4 style={{marginTop: '40px', marginBottom: '0px'}}>Interactr Pro Plus</h4>
            {authUser.usage_plan.name == "interactr_pro" ? <a target="_blank" href="https://app.paykickstart.com/checkout/rY7neP8l4O32dWaoD7Dj5EqG1pRoDw9V" rel="noreferrer">Upgrade Here</a> :  <p>Already Purchased</p>} */}

						<h4 style={{ marginTop: "40px", marginBottom: "0px" }}>
							Interactr Agency
						</h4>
						{authUser.is_agency ? (
							<p>Already Purchased</p>
						) : (
							<a
								style={{ color: "blue" }}
								target="_blank"
								href="https://interactr.io/upgrade/agency"
								rel="noreferrer"
							>
								Upgrade Here
							</a>
						)}

						<h4 style={{ marginTop: "40px", marginBottom: "15px" }}>
							Interactr Masterclass
						</h4>
						<a
							style={{ color: "blue" }}
							target="_blank"
							href="https://interactr.io/upgrade/masterclass"
							rel="noreferrer"
						>
							Upgrade Here
						</a>

						<h4 style={{ marginTop: "40px", marginBottom: "15px" }}>
							Purchase 1,000 Overage Minutes
						</h4>
						<a
							style={{ color: "blue" }}
							target="_blank"
							href="https://app.paykickstart.com/checkout/pAYXBlDw6VGPdkggdKgkgqx0Z9Q1y5mR"
							rel="noreferrer"
						>
							Purchase Here
						</a>

						<h4 style={{ marginTop: "40px", marginBottom: "15px" }}>
							Purchase 5,000 Overage Minutes
						</h4>
						<a
							style={{ color: "blue" }}
							target="_blank"
							href="https://app.paykickstart.com/checkout/45nv3ME1weJgxz3JOdEjdL07plRBGqDo"
							rel="noreferrer"
						>
							Purchase Here
						</a>

						<h4 style={{ marginTop: "40px", marginBottom: "15px" }}>
							Purchase 20,000 Overage Minutes
						</h4>
						<a
							style={{ color: "blue" }}
							target="_blank"
							href="https://app.paykickstart.com/checkout/qVBwA5YD1K90djOX6DazGEovQr4OJnyp"
							rel="noreferrer"
						>
							Purchase Here
						</a>
					</div>
				</div>
			</div>
		</PageBody>
	);
};
// export default UpgradePage;
