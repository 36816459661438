import React from "react";
import getAsset from "utils/getAsset";
import Button from "components/Buttons/Button";
///import { Select } from './components/Select';
import { useLocation } from "react-router-dom";
import { UploadType } from "../../../media/components/uploadMedia/SelectUploadTypeModal";

// https://stackblitz.com/~/github.com/samuelweckstrom/react-record-webcam?file=demo/src/App.tsx&view=editor
export const AddVideoForm = () => {
	const selectType = () => {};
	return (
		<div className="max-w-7xl mx-auto text-center"> 
		<h2 className="text-2xl font-semibold">Add a video to your Project</h2>
		<h3 className="mb-3 mt-1 text-xl">Select a media Type</h3>
	<div className="grid grid-cols-4 gap-x-4 mt-6 bg-[#F3F6FC] p-4 rounded-xl">
			<UploadType
				heading={"Record Video"}
				description={"Record a video using your webcam or phone camera"}
				image={getAsset("/img/img-upload-url.png")}
				setType={() => SelectType(SHOW_UPLOAD_FROM_URL_MODAL)}
			/>
			<UploadType
				heading={"Upload File"}
				description={"Upload a video file saved on your computer or phone"}
				image={getAsset("/img/img-upload-file.png")}
				setType={() => SelectType(SHOW_UPLOAD_FROM_FILE_MODAL)}
			/>
			<UploadType
				heading={"Stock Media"}
				description={"Select from our collection of stock videos and images"}
				image={getAsset("/img/img-stock-video.png")}
				setType={() => SelectType(SHOW_UPLOAD_FROM_STOCK_MODAL)}
			/>
			<UploadType
				heading={"Media Library"}
				description={"Select a file previously uploaded to a different project"}
				image={getAsset("/img/img-media-library.png")}
				setType={() => SelectType(SHOW_UPLOAD_FROM_LIBRARY_MODAL)}
			/>
		</div>
		</div>
	
	);
};
