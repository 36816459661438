import { useReactiveVar } from "@apollo/client";
import Button from "components/Buttons/Button";
import LinkButton from "components/Buttons/LinkButton";
import VideoPlayer from "components/VideoPlayer";
import _map from "lodash/map";
import React, { useState } from "react";
import apis from "utils/apis";
import Icon from "../../../components/Icon";
import { getAcl } from "../../../graphql/LocalState/acl";
import styles from "./uploadMedia/StockListModalStyles.module.scss";
import { toast } from "react-toastify";
import { getAddMedia } from "@/graphql/LocalState/addMedia";
import { getMediaRatio } from "utils/mediaUtils";
import { useTusUploader } from "../useTusUploader";
import { useProject } from "@/graphql/Project/hooks";
import { useMediaLibraryRoute } from 'modules/media/routeHooks';
import { useMedias } from '@/graphql/Media/hooks';
import {ADD_MEDIA_VAR_INITIAL_DATA, setAddMedia} from "@/graphql/LocalState/addMedia";
import {MEDIA_LIBRARY_QUERY_PARAMS} from "./UploadMediaItem";

const StockItem = ({ stockItem, user, onSelect, setFilterText, isImage }) => {
	const acl = useReactiveVar(getAcl);
	const { projectRatio } = useReactiveVar(getAddMedia);

	const handleTagClick = (tag) => {
		const loading = true,
			filteredTag = tag.toLowerCase().trim();

		setFilterText(filteredTag, loading);
	};

	if (!stockItem) return null;

	return (
		<div className={styles.listItem}>
			<div className={styles.listItemInner}>
				<div className={styles.videoHolder}>
					{isImage ? (
						<ImageItem stockItem={stockItem} />
					) : (
						<VideoItem stockItem={stockItem} />
					)}
				</div>
				<div className={styles.textHolder}>
					<Tags
						handleClick={handleTagClick}
						tagItems={stockItem.tags}
						stockItemId={stockItem.id}
					/>
					<Hearts count={stockItem.favorites} />
					<div className={styles.addButton}>
						{acl.canAccessStockFootage ? (
							isImage ? (
								<SelectImageButton stockItem={stockItem} onSelect={onSelect} />
							) : (
								<SelectVideoButton stockItem={stockItem} onSelect={onSelect} />
							)
						) : (
							<NoAccessButton />
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const Tags = ({ tagItems, stockItemId, handleClick }) => {
	const tags = tagItems.split(",");

	return (
		<div className={styles.tagHolder}>
			{_map(tags, (tag) => (
				<small
					onClick={() => handleClick(tag)}
					key={Math.random() * stockItemId}
					className={styles.tag}
				>
					{tag},
				</small>
			))}
		</div>
	);
};

const Hearts = ({ count }) => {
	return (
		<div className={styles.hearts}>
			<Icon name={"heart"} /> {count}
		</div>
	);
};

const ImageItem = ({ stockItem }) => {
	return (
		<img
			style={{ width: "100%", height: "138px" }}
			src={stockItem.previewURL}
		/>
	);
};

const VideoItem = ({ stockItem }) => {
	const getImageURL = () => {
		const { picture_id, videos } = stockItem;
		return videos?.large?.thumbnail;
		//return import.meta.env.VITE_PIXABAY_IMAGE_ROOT + "/" + picture_id + '_' + import.meta.env.VITE_PIXABAY_IMAGE_SIZE + '.jpg';
	};
	const getVideoDuration = () => {
		let { duration } = stockItem;

		if (duration < 10) {
			duration = "0" + duration;
		}

		return `00:${duration}`;
	};

	return (
		<>
			<VideoPlayer
				url={stockItem.videos.tiny.url}
				videoId={stockItem.id}
				imageURL={getImageURL()}
				controls
				light={getImageURL() || false} // stops preload and only shows thumbnail awesome for perf
			/>
			<span className={styles.videoDuration}>{getVideoDuration()}</span>
		</>
	);
};

const SelectImageButton = () => {
	const [uploading, setUploading] = useState(false);
	const handleBtnSelectClick = async () => {
		let mediaRatio = null;
		if (isImage) {
			mediaRatio = projectRatio; //getMediaRatio(stockItem?.imageWidth, stockItem?.imageHeight);
		} else {
			mediaRatio = getMediaRatio(
				stockItem?.videos?.large?.width,
				stockItem?.videos?.large?.height
			);
		}

		if (projectRatio != mediaRatio) {
			toast.info(
				`Selected media ratio is ${mediaRatio}, but project ratio is ${projectRatio}`,
				{
					position: "top-right",
					theme: "colored",
				}
			);
			return;
		}

		try {
			setUploading(true);
			const path = await uploadFile(
				isImage ? stockItem?.largeImageURL : stockItem.videos.large.url
			);
			onSelect({
				is_image: isImage ? 1 : 0,
				temp_storage_url: path,
				// thumbnail_url: stockItem.largeImageURL,
			});
		} catch (e) {
			console.log(e);
		} finally {
			setUploading(false);
		}
	};

	const uploadFile = async (url) => {
		const params = {
			url: url,
		};
		const res = await apis.uploadFileFromUrl(params);
		return res.filePath;
	};
	return (
		<Button
			primary
			noMarginRight={true}
			small
			right
			loading={uploading}
			onClick={handleBtnSelectClick}
		>
			{uploading ? "Uploading " : "Select "}
			{uploading ? (
				<Icon name="spinner-third" spin={uploading} />
			) : (
				<Icon name="arrow-right" />
			)}
		</Button>
	);
};

const SelectVideoButton = ({stockItem, onSelect}) => {
	const [project] = useProject();
	const [{ isOpen, activeTab, page, q, filterBy }] = useMediaLibraryRoute();
	const [m,n , { refetch }] = useMedias({
		...MEDIA_LIBRARY_QUERY_PARAMS,
		q,
		filterBy,
		page: parseInt(page),
	});
	
	const { loading, error, progress, start, setLoading } = useTusUploader();
	const handleBtnSelectClick = async () => {
		try {
			setLoading(true);
			// Step 1: Fetch the file from the URL
			const response = await fetch(stockItem.videos.large.url);
			if (!response.ok) throw new Error("Failed to fetch file");

			// Step 2: Convert the response to a Blob
			const file = await response.blob();
			await start({
				file, 
				type: "video/mp4",
				name: stockItem.tags,
				projectId: project.id,
				onComplete: (media)=>{
					refetch();
					// Close and reset the modal
					setAddMedia(ADD_MEDIA_VAR_INITIAL_DATA)
				}
			})
		} catch (e) {
			setLoading(false);
			console.error(e);
		}
	};
	return (
		<Button
			primary
			noMarginRight={true}
			small
			right
			loading={loading}
			onClick={handleBtnSelectClick}
		>
			{loading ? "Uploading: " + progress + '%' : "Select "}
			{loading ? (
				<Icon name="spinner-third" spin={loading} />
			) : (
				<Icon name="arrow-right" />
			)}
		</Button>
	);
};

const NoAccessButton = () => {
	return (
		<LinkButton primary right noMarginRight={true} small to="/upgrade">
			Upgrade
		</LinkButton>
	);
};

export default StockItem;
