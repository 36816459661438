import React from 'react';
import styles from './DashboardPage.module.scss';
import {DifferenceSpan} from "../../agency/components/ClientSummaryStat";
import {numberWithCommas} from "../../../utils/numberUtils";
import getAsset from "../../../utils/getAsset";
import Icon from "../../../components/Icon";
import cx from "classnames";


export default function DashboardCard ({currentValue, previousValue, suffix = '', heading, loading = false, style, description, iconPath}) {

  // Temp override util we have icons made
  const icon = getAsset(iconPath);
  
  return (
    <div className="rounded-xl shadow-md  relative mt-8 mb-6"  style={style}>
      <div className={styles.statContent}>
        <h3
          style={{cursor: 'pointer'}}
          data-tip={description}
		  className="text-xl font-medium"
        >
          {heading} <Icon name={'info-circle'} />
        </h3>
		<div className="flex items-center gap-x-4">
			<h1 className="text-2xl font-semibold">{ (loading) ? <Icon loading/> : numberWithCommas(currentValue) + suffix }</h1>
        	<p><DifferenceSpan currentStat={currentValue}  previousStat={previousValue} loading={loading} /></p>
		</div>
        {/*{*/}
        {/*  (loading) ?*/}
        {/*    'loading' :*/}
        {/*    <p className={styles.lastMonth}>Previous 30 Days {numberWithCommas(previousValue)}{suffix}</p>*/}
        {/*}*/}
      </div>
      <img src={icon} className={cx(styles.statIcon,'shadow-xl')}/>
    </div>
  );
}