import { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuthUser, useSaveUser } from '@/graphql/User/hooks';

export default function useAweberAccounts() {
	const APP_URL = import.meta.env.VITE_SHARE_APP_URL;

	const [saveUser] = useSaveUser();
	const user = useAuthUser();
	const { refresh_token } = user.integration_aweber ?? {};

	const [loading, setLoading] = useState(false);
	const [accounts, setAccounts] = useState([]);
	const [selectedAccount, setSelectedAccount] = useState(null);

	useEffect(() => {
		if (refresh_token) {
			fetchAccounts(refresh_token);
		}
	}, [refresh_token]);

	const fetchAccounts = (refresh_token) => {
		setLoading(true);

		axios
			.get(`${APP_URL}/api/aweber/accounts`, {
				params: {
					refresh_token,
				},
			})
			.then(({ data: { entries } }) => {
				setAccounts(entries);

				setSelectedAccount(entries.find((entry) => entry.id === user.integration_aweber?.account_id));

				setLoading(false);
			});
	};

	const select = (account) => {
		setLoading(true);
		
		saveUser({
			id: user.id,
			integration_aweber: {
				...user.integration_aweber,
				account_id: account?.id ?? null,
			},
		}).then(() => {
			setLoading(false);
		});
	};

	const disconnect = () => {
		setLoading(true);

		saveUser({
			id: user.id,
			integration_aweber: {
				refresh_token: null,
				account_id: null,
			},
		}).then(() => {
			setLoading(false);
		});
	};

	return {
		loading,
		accounts,
		selectedAccount,
		select,
		disconnect,
	};
}
