import React, { useState } from 'react';
import Modal from 'components/Modal';
import { useGetActiveElementMeta } from "../graphql/Element/hooks";
import Emitter, { INSERT_DYNAMIC_TEXT } from "../utils/EventEmitter";
import Button from "./Buttons/Button";
import Icon from "./Icon";
import { Option, TextInput } from "./PropertyEditor";
import { toast } from "react-toastify";

const NewDynamicText = ({setShow}) => {
  const {element_id, element_type, loading, error} = useGetActiveElementMeta();

  if(loading) return <Icon loading />;

  if(error) return  null;

  return(
      <NewDynamicTextModal
        setShow={setShow}
        element_id={element_id}
        element_type={element_type}
      />
  )
};
export default NewDynamicText;

const NewDynamicTextModal = ({setShow, element_id, element_type }) => {

  const [text, setText] = useState("");
  const [defaultValue, setDefaultValue] = useState("");

  const handleSave = async () => {
    try {
      Emitter.emit(INSERT_DYNAMIC_TEXT, {name: text, defaultValue: defaultValue, element_id, element_type});
      setShow(false);
    } catch(e) {
      toast.error(e.message, {
        position: 'top-right',
        theme:"colored"
      });
    }
  };

  return(
    <Modal
      width={350}
      height={400}
      show={true}
      onClose={()=>setShow(false)}
      heading={
        <>
          <Icon name="code" />
          Insert New Dynamic Text
        </>
      }
      submitButton={
        <Button primary icon={'code'} onClick={handleSave}>Insert Dynamic Text</Button>
      }
    >
      <div>
        <Option
          label='Enter a name for the dynamic text'
          value={text}
          Component={TextInput}
          onChange={val=>setText(val)}
        />        
        <Option
          label="Give the text a default value"
          value={defaultValue}
          Component={TextInput}
          onChange={val=>setDefaultValue(val)}
        />
        <p>
          To find out more about how to use dynamic text read our support article
          <a href="https://support.videosuite.io/article/25-using-dynamic-text-to-personalise-your-interactive-videos" target={'_blank'} style={{color: 'blue'}}> here</a>
        </p>
      </div>
    </Modal>
  )
};